import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useGetPermisosBackofficePaginatedMutation } from '../../../store/api/endpoints/permisosBackofficeApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../elementos/ButtonIcon'
import HeaderVista from '../../elementos/HeaderVista'
import TablaPaginada from '../../elementos/TablaPaginada'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import LockOpenIcon from '@mui/icons-material/LockOpen'

import SearchIcon from '@mui/icons-material/Search'


export default function Planes() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const thead = []
    thead.push({ label: "Codigo", align: "right", ordenable: true })
    thead.push({ label: "Nombre", align: "left", ordenable: true })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "codigo" })
    tbody.push({ tipo: "texto", valor: "nombre" })
    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/permisos/backoffice/", valor: "_id" })
    tbody.push({ tipo: "botones", valor: botones })

    if (permisosDelUsuario.indexOf(5) == -1) return (<p>No tienes acceso a visualizar los permisos.</p>)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.primary">Permisos Backoffice</Typography>
                </Breadcrumbs>
            }
            icon={<LockOpenIcon />}
            end={(permisosDelUsuario.indexOf(8) > -1 ? <ButtonIcon
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                to="/permisos/backoffice/crear"
            >
                Nuevo
            </ButtonIcon> : '')}
        />
        <TablaPaginada
            api={useGetPermisosBackofficePaginatedMutation}
            thead={thead}
            tbody={tbody}
            orderDefault={[0,0]}
        />
    </>)

}

