import { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useGetClienteByIdMutation, useGetClienteRutasMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import HeaderVista from '../../../elementos/HeaderVista'
import TituloMini from '../../../elementos/TituloMini'
import Card from '../../../elementos/Card'
import ButtonIcon from '../../../elementos/ButtonIcon'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

export default function ClienteRutasIndex() {

    const urlParams = useParams()
    const dispatch = useDispatch()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
            error: errorDataGetCliente,
        }
    ] = useGetClienteByIdMutation()

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteRutasMutation()

    useEffect(() => {
        getCliente(urlParams.id)
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetCliente) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetCliente.data.message
            }))
        }
    }, [isErrorGetCliente])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar los clientes.</p>)

    if (isUninitializedGetCliente || isLoadingGetCliente || isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetCliente.dominio}
                    </Link>
                    <Typography color="text.primary">Editar Imágenes</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <TituloMini titulo="Archivos e Imágenes">Estos archivos conforman la vista de clientes.</TituloMini>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} >
                    <Card
                        titulo="Logo"
                        img={responseGetRegistro.logo}
                        descripcion="Es el logo de la marca. Esta imágen aparecerá en la vista de cliente."
                        confirmDelete={
                            (responseGetRegistro.logo !== '' ?
                                {
                                    httpDelete: '/clientes/' + urlParams.id + '/edit/rutas/logo/remove',
                                    returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                }
                                : '')
                        }
                        rutaPencil={
                            (responseGetRegistro.logo === '' ?
                                ('/clientes/' + urlParams.id + '/edit/rutas/logo')
                                : '')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <Card
                        titulo="Socials"
                        img={responseGetRegistro.socials}
                        descripcion="Es la imágen que aparece al compartir el link por whatsapp, facebook, instagram o redes sociales."
                        confirmDelete={
                            (responseGetRegistro.socials !== '' ?
                                {
                                    httpDelete: '/clientes/' + urlParams.id + '/edit/rutas/socials/remove',
                                    returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                }
                                : '')
                        }
                        rutaPencil={
                            (responseGetRegistro.socials === '' ?
                                ('/clientes/' + urlParams.id + '/edit/rutas/socials')
                                : '')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <Card
                        titulo="Favicon"
                        img={responseGetRegistro.favicon}
                        descripcion="Es el icono que aparece en la pestaña del navegador, tanto en la vista de clientes como administradores."
                        confirmDelete={
                            (responseGetRegistro.favicon !== '' ?
                                {
                                    httpDelete: '/clientes/' + urlParams.id + '/edit/rutas/favicon/remove',
                                    returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                }
                                : '')
                        }
                        rutaPencil={
                            (responseGetRegistro.favicon === '' ?
                                ('/clientes/' + urlParams.id + '/edit/rutas/favicon')
                                : '')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <Card
                        titulo="Banner 1"
                        img={responseGetRegistro.banner1}
                        descripcion="Imagen de portada que aparece en la web (antes del listado de servicios)."
                        confirmDelete={
                            (responseGetRegistro.banner1 !== '' ?
                                {
                                    httpDelete: '/clientes/' + urlParams.id + '/edit/rutas/banner1/remove',
                                    returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                }
                                : '')
                        }
                        rutaPencil={
                            (responseGetRegistro.banner1 === '' ?
                                ('/clientes/' + urlParams.id + '/edit/rutas/banner1')
                                : '')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <Card
                        titulo="Banner 2"
                        img={responseGetRegistro.banner2}
                        descripcion="Imagen de portada que aparece en la web (después del listado de servicios)."
                        confirmDelete={
                            (responseGetRegistro.banner2 !== '' ?
                                {
                                    httpDelete: '/clientes/' + urlParams.id + '/edit/rutas/banner2/remove',
                                    returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                }
                                : '')
                        }
                        rutaPencil={
                            (responseGetRegistro.banner2 === '' ?
                                ('/clientes/' + urlParams.id + '/edit/rutas/banner2')
                                : '')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <Card
                        titulo="CSS"
                        descripcion="Archivo de estilos si la marca pide personalizar la vista de cliente. No se utiliza casi nunca."
                        confirmDelete={
                            (responseGetRegistro.css !== '' ?
                                {
                                    httpDelete: '/clientes/' + urlParams.id + '/edit/rutas/css/remove',
                                    returnUrl: '/clientes/' + urlParams.id + '/edit/rutas'
                                }
                                : '')
                        }
                        rutaPencil={
                            (responseGetRegistro.css === '' ?
                                ('/clientes/' + urlParams.id + '/edit/rutas/css')
                                : '')
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    </>)

}

