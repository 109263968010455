import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useCreateClienteAgendaMutation } from '../../../../store/api/endpoints/clientesagendasApiSlice'
import { useGetClientesSucursalesPaginatedMutation } from '../../../../store/api/endpoints/clientessucusalesApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'
import Box from '@mui/material/Box'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import AutocompletarMauri from '../../../elementos/AutocompletarMauri'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import PersonIcon from '@mui/icons-material/Person'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PlaceIcon from '@mui/icons-material/Place'

export default function ClienteAgendasNueva() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [nombre, setNombre] = useState('')
    const [sucursal, setSucursal] = useState(null)
    const [visualiza_cliente, setVisualizaCliente] = useState(1)
    const [options_sucursales, setOptionsSucursales] = useState([])
    const [sucursalInput, setSucursalInput] = useState('')
    const [searchTextoSucursal, setSearchTextoSucursal] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()


    const [
        crearRegistro,
        {
            data: responseCreateRegistro,
            isUninitialized: isUninitializedCreateRegistro,
            isLoading: isLoadingCreateRegistro,
            isSuccess: isSuccessCreateRegistro,
            isError: isErrorCreateRegistro,
            error: errorDataCreateRegistro,
        }
    ] = useCreateClienteAgendaMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            nombre,
            sucursal,
            visualiza_cliente,
        }
        crearRegistro({ idCliente: urlParams.id, body })
    }


    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorCreateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreateRegistro.data.message
            }))
        }
    }, [isErrorCreateRegistro])

    useEffect(() => {
        if (isSuccessCreateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡La agenda se ha creado!"
            }))

            navigate("/clientes/" + urlParams.id + "/app")
        }
    }, [isSuccessCreateRegistro])

    if (permisosDelUsuario.indexOf(10) == -1) return (<p>No tienes acceso a crear sucursales.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingCreateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/app"}>
                        App
                    </Link>
                    <Typography color="text.primary">Nueva Sucursal</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} >
                        <AutocompletarMauri
                            label="Sucursal"
                            api={useGetClientesSucursalesPaginatedMutation}
                            orderDefault={[0, 0]}
                            extraParams={{idCliente: urlParams.id}}
                            optionsEstructura = {['codigo', ['barrio','nombre'], [], ' - ']}
                            icon={<PlaceIcon />}
                            value={sucursal}
                            setValue={setSucursal}
                            required
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Input
                            label="Nombre"
                            icon={<PersonIcon />}
                            helperText="Ej: Liniers"
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Select
                            label="Visualiza Cliente"
                            icon={<RemoveRedEyeIcon />}
                            value={visualiza_cliente}
                            options={[
                                { value: 0, label: 'No' },
                                { value: 1, label: 'Sí' }
                            ]}
                            onChange={setVisualizaCliente}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

