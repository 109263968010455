import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetPermisoAppMutation, useUpdatePermisoAppMutation } from '../../../store/api/endpoints/permisosAppApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import CheckIcon from '@mui/icons-material/Check'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import NumbersIcon from '@mui/icons-material/Numbers'
import NotesIcon from '@mui/icons-material/Notes'

export default function PermisosAppEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const urlParams = useParams()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetPermisoAppMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdatePermisoAppMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            codigo,
            nombre,
            descripcion,
        }
        updateRegistro({ id: urlParams.idPermiso, app: urlParams.app, body: body })
    }

    useEffect(() => {
        getRegistro({ app: urlParams.app, id: urlParams.idPermiso })
    }, [])

    useEffect(() => {
        if (isSuccessGetRegistro) {
            setCodigo(responseGetRegistro.codigo)
            setNombre(responseGetRegistro.nombre)
            setDescripcion(responseGetRegistro.descripcion)
        }
    }, [isSuccessGetRegistro])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/permisos/app/" + urlParams.app)

        }
    }, [isSuccessUpdateRegistro])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    const [codigo, setCodigo] = useState('')
    const [nombre, setNombre] = useState('')
    const [descripcion, setDescripcion] = useState('')

    if (permisosDelUsuario.indexOf(14) == -1) return (<p>No tienes acceso a editar los permisos.</p>)

    if (isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.primary">Permisos App</Typography>
                    <Link underline="hover" color="inherit" to={"/permisos/app/" + urlParams.app} style={{ textTransform: 'uppercase' }}>
                        {urlParams.app}
                    </Link>
                    <Typography color="text.primary">Editar</Typography>
                </Breadcrumbs>
            }
            icon={<LockOpenIcon />}
            end={<ConfirmDelete
                httpDelete={"permisos/" + urlParams.app + "/" + urlParams.idPermiso}
                returnUrl={"/permisos/app/" + urlParams.app}
                variant="outlined"
                size="small"
            >
                Eliminar
            </ConfirmDelete>}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Codigo"
                            icon={<NumbersIcon />}
                            value={codigo}
                            onChange={setCodigo}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Nombre"
                            icon={<LockOpenIcon />}
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Input
                            label="Descripcion"
                            icon={<NotesIcon />}
                            value={descripcion}
                            onChange={setDescripcion}
                            type="text"
                            multiline
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>

    </>)

}

