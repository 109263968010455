import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useGetPaginatedMutation } from '../../../store/api/endpoints/clientesApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../elementos/ButtonIcon'
import HeaderVista from '../../elementos/HeaderVista'
import TablaPaginada from '../../elementos/TablaPaginada'

import AddIcon from '@mui/icons-material/Add'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

import SearchIcon from '@mui/icons-material/Search'


export default function Clientes() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const thead = []
    thead.push({ label: "Alta", align: "center", ordenable: true })
    thead.push({ label: "Tipo", align: "left", ordenable: true })
    thead.push({ label: "Marca", align: "left", ordenable: true })
    thead.push({ label: "Dominio", align: "left", ordenable: true })
    thead.push({ label: "Nombre", align: "left", ordenable: true })
    thead.push({ label: "Email", align: "left", ordenable: true })
    thead.push({ label: "Teléfono", align: "left", ordenable: true })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "fecha", valor: "alta" })
    tbody.push({ tipo: "texto", valor: "tipo_app" })
    tbody.push({ tipo: "texto", valor: "marca" })
    tbody.push({ tipo: "texto", valor: "dominio" })
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "texto", valor: "email" })
    tbody.push({ tipo: "texto", valor: "telefono" })
    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/clientes/", valor: '_id' })
    tbody.push({ tipo: "botones", valor: botones })


    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar los clientes.</p>)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.primary">Clientes</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
            end={(permisosDelUsuario.indexOf(9) > -1 ? <ButtonIcon
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                to="/clientes/crear"
            >
                Nuevo
            </ButtonIcon> : '')}
        />
        <TablaPaginada
            api={useGetPaginatedMutation}
            thead={thead}
            tbody={tbody}
            orderDefault={[0,1]}
        />
    </>)

}

