import { tiendasApi } from '../api'

export const authApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: 'self/login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        refreshToken: builder.mutation({
            query: token => ({
                url: 'self/refresh-token',
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            })
        }),
        getTokenForApp: builder.mutation({
            query: idCliente => ({
                url: 'self/token-for-app/' + idCliente,
                method: 'GET',
            })
        }),
        getSidebarData: builder.mutation({
            query: token => ({
                url: 'self/sidebar-data',
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            })
        }),
    })
})

export const {
    useLoginMutation,
    useRefreshTokenMutation,
    useGetTokenForAppMutation,
    useGetSidebarDataMutation,
} = authApiSlice