import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useRemoveClientePagoMutation } from '../../../../store/api/endpoints/clientespagosApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Select from '../../../elementos/Select'
import TituloMini from '../../../elementos/TituloMini'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

export default function ClientesPagosEliminar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    const [
        removeRegistro,
        {
            data: responseRemoveRegistro,
            isUninitialized: isUninitializedRemoveRegistro,
            isLoading: isLoadingRemoveRegistro,
            isSuccess: isSuccessRemoveRegistro,
            isError: isErrorRemoveRegistro,
            error: errorDataRemoveRegistro,
        }
    ] = useRemoveClientePagoMutation()

    const handleSubmit = () => {
        removeRegistro(urlParams.idPago)
    }


    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorRemoveRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataRemoveRegistro.data.message
            }))
        }
    }, [isErrorRemoveRegistro])

    useEffect(() => {
        if (isSuccessRemoveRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "El pago se eliminó correctamente."
            }))

            navigate("/clientes/" + urlParams.id + "/cuenta-corriente")
        }
    }, [isSuccessRemoveRegistro])


    if (permisosDelUsuario.indexOf(13) == -1) return (<p>No tienes acceso a eliminar pagos.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingRemoveRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/cuenta-corriente"}>
                        Cuenta Corriente
                    </Link>
                    <Typography color="text.primary">Eliminar Pago</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Eliminar Pago">Eliminarás el pago del cliente.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p>¿Seguro que deseas eliminar el pago del cliente?</p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="error" startIcon={<DeleteOutlineIcon />} >
                            Eliminar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

