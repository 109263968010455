import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetPermisoBackofficeMutation } from '../../../store/api/endpoints/permisosBackofficeApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import ButtonIcon from '../../elementos/ButtonIcon'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import CachedIcon from '@mui/icons-material/Cached'
import NumbersIcon from '@mui/icons-material/Numbers'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

export default function PermisoBackoffice() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetPermisoBackofficeMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isSuccessGetRegistro) {

            setCodigo(responseGetRegistro.codigo)
            setNombre(responseGetRegistro.nombre)

        }
    }, [isSuccessGetRegistro])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    const [codigo, setCodigo] = useState('')
    const [nombre, setNombre] = useState('')

    if (permisosDelUsuario.indexOf(5) == -1) return (<p>No tienes acceso a visualizar los permisos.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/permisos/backoffice"}>
                        Permisos Backoffice
                    </Link>
                    <Typography color="text.primary">{nombre}</Typography>
                </Breadcrumbs>
            }
            icon={<LockOpenIcon />}
            end={<>
                {(permisosDelUsuario.indexOf(8) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    to={"/permisos/backoffice/" + urlParams.id + "/editar"}
                    marginRight
                >
                    Editar
                </ButtonIcon> : '')}
                {(permisosDelUsuario.indexOf(8) > -1 ? <ConfirmDelete
                    httpDelete={"permisos/backoffice/" + urlParams.id}
                    returnUrl={"/permisos/backoffice"}
                    variant="outlined"
                    size="small"
                >
                    Eliminar
                </ConfirmDelete> : '')}
            </>}
        />
        <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Codigo"
                        icon={<NumbersIcon />}
                        value={codigo}
                        onChange={setCodigo}
                        type="tel"
                        restricciones={['soloNumeros']}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Nombre"
                        icon={<LockOpenIcon />}
                        value={nombre}
                        onChange={setNombre}
                        type="text"
                        disabled
                    />
                </Grid>
            </Grid>
        </Container>

    </>)

}

