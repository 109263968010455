import { React } from 'react'
import { useSelector } from 'react-redux'



export default function Home() {


    return (
        <>
            <p>Esto es el home</p>
        </>
    )

}