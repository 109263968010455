import { afipCodigosApi } from '../api'

export const tempApiSlice = afipCodigosApi.injectEndpoints({
    endpoints: builder => ({
        getAfipDocumentos: builder.mutation({
            query: () => ({
                url: 'documentos',
                method: 'GET',
            })
        }),
        getAfipComprobantes: builder.mutation({
            query: () => ({
                url: 'comprobantes',
                method: 'GET',
            })
        }),
        getAfipCondicioniva: builder.mutation({
            query: () => ({
                url: 'condicion-iva',
                method: 'GET',
            })
        }),
    })
})

export const {
    useGetAfipDocumentosMutation,
    useGetAfipComprobantesMutation,
    useGetAfipCondicionivaMutation,
} = tempApiSlice