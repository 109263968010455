import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation, useUpdateClienteContactoMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetAllUsuariosMutation } from '../../../../store/api/endpoints/usuariosApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import TituloMini from '../../../elementos/TituloMini'
import Select from '../../../elementos/Select'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import AccountCircle from '@mui/icons-material/AccountCircle'
import PersonIcon from '@mui/icons-material/Person'
import CheckIcon from '@mui/icons-material/Check'

export default function ClienteContactoUpdate() {

    const urlParams = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [nombre, setNombre] = useState('')
    const [telefono, setTelefono] = useState('')
    const [email, setEmail] = useState('')
    const [vendedor, setVendedor] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    const [
        getVendedores,
        {
            data: responseGetVendedores,
            isUninitialized: isUninitializedGetVendedores,
            isLoading: isLoadingGetVendedores,
            isSuccess: isSuccessGetVendedores,
            isError: isErrorGetVendedores,
            error: errorDataGetVendedores,
        }
    ] = useGetAllUsuariosMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateClienteContactoMutation()

    const handleSubmit = () => {

        let body = {
            nombre,
            email,
            telefono,
            vendedor,
        }

        updateRegistro({ id: urlParams.id, body })
    }

    useEffect(() => {
        getVendedores()
    }, [])

    useEffect(() => {
        if (isErrorGetVendedores) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetVendedores.data.message
            }))
        }
    }, [isErrorGetVendedores])

    useEffect(() => {
        if (isSuccessGetVendedores) {
            getRegistro(urlParams.id)
        }
    }, [isSuccessGetVendedores])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

            navigate("/clientes/" + urlParams.id)
        }
    }, [isSuccessUpdateRegistro])

    useEffect(() => {

        if (isSuccessGetRegistro) {

            setNombre(responseGetRegistro.nombre)
            setEmail(responseGetRegistro.email)
            setTelefono(responseGetRegistro.telefono)
            setVendedor(responseGetRegistro.vendedorId)

        }

    }, [isSuccessGetRegistro])

    if (permisosDelUsuario.indexOf(9) == -1) return (<p>No tienes acceso a modificar los clientes.</p>)

    if (isUninitializedGetVendedores || isLoadingGetVendedores || isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.dominio}
                    </Link>
                    <Typography color="text.primary">Editar Contacto</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Info de Contacto">Información de contacto de uso interna de doyTurnos.com</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Nombre"
                            icon={<AccountCircle />}
                            helperText="Del contacto o dueño"
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Teléfono"
                            icon={<WhatsAppIcon />}
                            helperText="Del contacto o dueño"
                            value={telefono}
                            onChange={setTelefono}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Email"
                            icon={<MailOutlineIcon />}
                            helperText="Del contacto o dueño"
                            value={email}
                            onChange={setEmail}
                            type="email"
                            restricciones={['email']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Select
                            label="Vendedor"
                            icon={<PersonIcon />}
                            value={vendedor}
                            options={responseGetVendedores.usuarios.map(function (e) {
                                return {
                                    value: e._id,
                                    label: e.nombre
                                }
                            })}
                            onChange={setVendedor}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

