import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { NumericFormat } from 'react-number-format'
import Moment from 'react-moment'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetPlanVigenteMutation } from '../../../../store/api/endpoints/clientesplanesApiSlice'
import { useCreateClientePagoMutation } from '../../../../store/api/endpoints/clientespagosApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import DisplayInfoUl from '../../../elementos/DisplayInfoUl'
import TituloMini from '../../../elementos/TituloMini'
import Input from '../../../elementos/Input'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

export default function ClientePlanesNevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [cliente_plan, setClientePlan] = useState('')
    const [importe, setImporte] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    const [
        getPlanVigente,
        {
            data: responseGetPlanVigente,
            isUninitialized: isUninitializedGetPlanVigente,
            isLoading: isLoadingGetPlanVigente,
            isSuccess: isSuccessGetPlanVigente,
            isError: isErrorGetPlanVigente,
            error: errorDataGetPlanVigente,
        }
    ] = useGetPlanVigenteMutation()

    const [
        crearRegistro,
        {
            data: responseCreateRegistro,
            isUninitialized: isUninitializedCreateRegistro,
            isLoading: isLoadingCreateRegistro,
            isSuccess: isSuccessCreateRegistro,
            isError: isErrorCreateRegistro,
            error: errorDataCreateRegistro,
        }
    ] = useCreateClientePagoMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            cliente: urlParams.id,
            cliente_plan,
            importe,
        }
        crearRegistro(body)
    }


    useEffect(() => {
        getRegistro(urlParams.id)
        getPlanVigente(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorGetPlanVigente) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetPlanVigente.data.message
            }))
        }
    }, [isErrorGetPlanVigente])

    useEffect(() => {
        if (isSuccessGetPlanVigente) {
            setClientePlan(responseGetPlanVigente.plan.id)
        }
    }, [isSuccessGetPlanVigente])

    useEffect(() => {
        if (isErrorCreateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreateRegistro.data.message
            }))
        }
    }, [isErrorCreateRegistro])

    useEffect(() => {
        if (isSuccessCreateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡El pago se ha creado!"
            }))

            navigate("/clientes/" + urlParams.id + "/cuenta-corriente")
        }
    }, [isSuccessCreateRegistro])

    if (permisosDelUsuario.indexOf(12) == -1) return (<p>No tienes acceso a crear pagos.</p>)

    if (isUninitializedGetPlanVigente || isLoadingGetPlanVigente || isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingCreateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/cuenta-corriente"}>
                        Cuenta Corriente
                    </Link>
                    <Typography color="text.primary">Nuevo Pago</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Nuevo Pago">Completa la información del pago.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DisplayInfoUl
                            titulo="Info del Pago"
                            data={
                                [
                                    { index: 'Plan', value: responseGetPlanVigente.plan.nombre },
                                    {
                                        index: 'Deuda', value: <NumericFormat
                                            value={responseGetPlanVigente.deuda.deuda_importe}
                                            className="foo"
                                            displayType={'text'}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            prefix={'$ '}
                                        />
                                    },
                                    { index: 'Pago Actual', value: <Moment date={responseGetPlanVigente.deuda.abono_proximo_periodo} format="M - Y" /> },
                                ]
                            }
                        />
                        <br />
                        <Input
                            label="Importe"
                            icon={<AttachMoneyIcon />}
                            value={importe}
                            onChange={setImporte}
                            type="tel"
                            restricciones={['num']}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

