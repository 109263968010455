import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useCreateUsuarioMutation, useGetAllUsuariosMutation } from '../../../store/api/endpoints/usuariosApiSlice'
import { useGetPermisosBackofficeAllMutation } from '../../../store/api/endpoints/permisosBackofficeApiSlice'

import { getUsuarioPermisos, forzarRefreshToken } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import CheckboxList from '../../elementos/CheckboxList'

import CheckIcon from '@mui/icons-material/Check'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PercentIcon from '@mui/icons-material/Percent'
import PasswordIcon from '@mui/icons-material/Password'
import LockOpenIcon from '@mui/icons-material/LockOpen'

export default function UsuariosCrear() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getPermisosAll,
        {
            data: responseGetPermisosAll,
            isUninitialized: isUninitializedGetPermisosAll,
            isLoading: isLoadingGetPermisosAll,
            isSuccess: isSuccessGetPermisosAll,
            isError: isErrorGetPermisosAll,
            error: errorDataGetPermisosAll,
        }
    ] = useGetPermisosBackofficeAllMutation()

    const [
        getUsuariosAll,
        {
            data: responseGetUsuariosAll,
            isUninitialized: isUninitializedGetUsuariosAll,
            isLoading: isLoadingGetUsuariosAll,
            isSuccess: isSuccessGetUsuariosAll,
            isError: isErrorGetUsuariosAll,
            error: errorDataGetUsuariosAll,
        }
    ] = useGetAllUsuariosMutation()

    const [
        createRegistro,
        {
            data: responseCreateRegistro,
            isUninitialized: isUninitializedCreateRegistro,
            isLoading: isLoadingCreateRegistro,
            isSuccess: isSuccessCreateRegistro,
            isError: isErrorCreateRegistro,
            error: errorDataCreateRegistro,
        }
    ] = useCreateUsuarioMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            nombre,
            email,
            tel_whatsapp,
            comision,
            contrasena,
            acceso_vendedores: vendedoresElegidos,
            permisos: permisosElegidos,
        }
        createRegistro(body)
    }

    useEffect(() => {
        getPermisosAll()
        getUsuariosAll()
    }, [])

    useEffect(() => {
        if (isSuccessGetPermisosAll && isSuccessGetUsuariosAll) {
            setVendedoresAll(responseGetUsuariosAll)
            setPermisosAll(responseGetPermisosAll)
        }
    }, [isSuccessGetPermisosAll, isSuccessGetUsuariosAll])

    useEffect(() => {
        if (isErrorGetPermisosAll) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetPermisosAll.data.message
            }))
        }
    }, [isErrorGetPermisosAll])

    useEffect(() => {
        if (isErrorGetUsuariosAll) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetUsuariosAll.data.message
            }))
        }
    }, [isErrorGetUsuariosAll])

    useEffect(() => {
        if (isSuccessCreateRegistro) {

            dispatch(forzarRefreshToken({}))

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/usuarios")

        }
    }, [isSuccessCreateRegistro])

    useEffect(() => {
        if (isErrorCreateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreateRegistro.data.message
            }))
        }
    }, [isErrorCreateRegistro])

    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [comision, setComision] = useState('0')
    const [contrasena, setContrasena] = useState('')
    const [permisosAll, setPermisosAll] = useState([])
    const [permisosElegidos, setPermisosElegidos] = useState([])
    const [vendedoresAll, setVendedoresAll] = useState([])
    const [vendedoresElegidos, setVendedoresElegidos] = useState([])

    if (permisosDelUsuario.indexOf(7) == -1) return (<p>No tienes acceso a crear los usuarios.</p>)

    if (isUninitializedGetPermisosAll || isLoadingGetPermisosAll || isUninitializedGetUsuariosAll || isLoadingGetUsuariosAll || isLoadingCreateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/usuarios"}>
                        Usuarios
                    </Link>
                    <Typography color="text.primary">Crear</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Nombre del Usuario"
                            icon={<PersonIcon />}
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Email"
                            icon={<AlternateEmailIcon />}
                            value={email}
                            onChange={setEmail}
                            type="email"
                            restricciones={['email']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Whatsapp"
                            icon={<WhatsAppIcon />}
                            value={tel_whatsapp}
                            onChange={setTelWhatsapp}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Comisión"
                            icon={<PercentIcon />}
                            value={comision}
                            onChange={setComision}
                            type="tel"
                            restricciones={['soloNumerosDecimales']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Contraseña"
                            icon={<PasswordIcon />}
                            value={contrasena}
                            onChange={setContrasena}
                            type="password"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br /><Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CheckboxList
                            titulo="Acceso Vendedores"
                            icon={<LockOpenIcon />}
                            elegidos={vendedoresElegidos}
                            opciones={vendedoresAll.map(elem => ({
                                codigo: elem._id,
                                nombre: elem.nombre,
                            }))}
                            handleChange={setVendedoresElegidos}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CheckboxList
                            titulo="Permisos"
                            icon={<LockOpenIcon />}
                            elegidos={permisosElegidos}
                            opciones={permisosAll}
                            handleChange={setPermisosElegidos}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>

    </>)

}

