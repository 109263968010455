import { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useGetClienteByIdMutation, useGetClienteRutasMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import HeaderVista from '../../../elementos/HeaderVista'
import TituloMini from '../../../elementos/TituloMini'
import Dropzone from '../../../elementos/Dropzone'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

export default function ClienteRutasUpload() {

    const urlParams = useParams()
    const dispatch = useDispatch()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
            error: errorDataGetCliente,
        }
    ] = useGetClienteByIdMutation()

    useEffect(() => {
        getCliente(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetCliente) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetCliente.data.message
            }))
        }
    }, [isErrorGetCliente])

    const urlApi = (window.location.href.includes("doyturnos.com") ? 'https://api-app.doyturnos.com/api/adm/' : 'http://localhost:3800/api/adm/')

    var formatosPermitidos = "";
    if (['logo', 'socials', 'banner1', 'banner2'].includes(urlParams.tipo)) {
        formatosPermitidos = 'image/jpeg, image/png';
    } else if (['favicon'].includes(urlParams.tipo)) {
        formatosPermitidos = 'image/x-icon';
    } else if (['css'].includes(urlParams.tipo)) {
        formatosPermitidos = 'text/css';
    }
    console.log(formatosPermitidos)

    var maxSize = 0;
    if (['logo', 'socials', 'css'].includes(urlParams.tipo)) {
        maxSize = 70000;
    } else if (['banner1', 'banner2', 'favicon'].includes(urlParams.tipo)) {
        maxSize = 200000;
    }

    if (permisosDelUsuario.indexOf(9) == -1) return (<p>No tienes acceso a modificar los clientes.</p>)

    if (isUninitializedGetCliente || isLoadingGetCliente) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetCliente.dominio}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/edit/rutas"}>
                        Editar Imágenes
                    </Link>
                    <Typography color="text.primary">{urlParams.tipo}</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <TituloMini titulo="Cargar Archivo">Aquí podrás cargar el archivo <b style={{textTransform: 'uppercase'}}>{urlParams.tipo}</b>.</TituloMini>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} >
                    <Dropzone
                        url={urlApi + "clientes/" + urlParams.id + "/ruta/" + urlParams.tipo}
                        returnUrl={'/clientes/' + urlParams.id + '/edit/rutas'}
                        formatosPermitidos={formatosPermitidos}
                        maxSize={maxSize}
                        maxFiles={1}
                    />
                </Grid>
            </Grid>
        </Container>

    </>)

}

