import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useGetClienteFacturacionMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import DisplayInfoUl from '../../../elementos/DisplayInfoUl'


export default function ClienteFacturacionGet() {

    const urlParams = useParams()
    const dispatch = useDispatch()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteFacturacionMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar los clientes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <DisplayInfoUl
            titulo="Facturación"
            editar={(permisosDelUsuario.indexOf(9) != -1 ? {
                path: '/clientes/' + urlParams.id + '/edit/facturacion'
            } : '')}
            data={
                [
                    { index: 'Activo', value: (responseGetRegistro.activo ? 'Sí' : 'No') },
                    { index: 'Tipo Documento', value: responseGetRegistro.tipo_documento },
                    { index: 'N° Documento', value: responseGetRegistro.numero_documento },
                    { index: 'Cond. Iva', value: responseGetRegistro.condicion_iva },
                    { index: 'Razon Social', value: responseGetRegistro.razon_social },
                    { index: 'Domicilio', value: responseGetRegistro.domicilio },
                    { index: 'Provincia', value: responseGetRegistro.provincia },
                ]
            }
        />
    </>)

}

