import { tiendasApi } from '../api'

export const clientesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPaginated: builder.mutation({
            query: credentials => ({
                url: 'clientes/get-paginated',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        getClienteById: builder.mutation({
            query: id => ({
                url: 'clientes/' + id,
                method: 'GET'
            })
        }),
        getClienteMarca: builder.mutation({
            query: id => ({
                url: 'clientes/' + id + '/marca',
                method: 'GET'
            })
        }),
        getClienteColores: builder.mutation({
            query: id => ({
                url: 'clientes/' + id + '/colores',
                method: 'GET'
            })
        }),
        getClienteRutas: builder.mutation({
            query: id => ({
                url: 'clientes/' + id + '/rutas',
                method: 'GET'
            })
        }),
        getClienteFacturacion: builder.mutation({
            query: id => ({
                url: 'clientes/' + id + '/facturacion',
                method: 'GET'
            })
        }),

        createCliente: builder.mutation({
            query: (body) => ({
                url: 'clientes/crear',
                method: 'POST',
                body: body
            })
        }),

        updateClienteMarca: builder.mutation({
            query: ({ id, body }) => ({
                url: 'clientes/' + id + '/marca',
                method: 'PUT',
                body: body
            })
        }),
        updateClienteContacto: builder.mutation({
            query: ({ id, body }) => ({
                url: 'clientes/' + id + '/contacto',
                method: 'PUT',
                body: body
            })
        }),
        updateClienteColores: builder.mutation({
            query: ({ id, body }) => ({
                url: 'clientes/' + id + '/colores',
                method: 'PUT',
                body: body
            })
        }),
        updateClienteFacturacion: builder.mutation({
            query: ({ id, body }) => ({
                url: 'clientes/' + id + '/facturacion',
                method: 'PUT',
                body: body
            })
        }),

        uploadRuta: builder.mutation({
            query(data) {
                const { idCliente, ruta, body } = data
                return {
                    url: '/clientes/' + idCliente + '/ruta/' + ruta,
                    method: 'POST',
                    body: body,
                }
            }
        }),
        removeRuta: builder.mutation({
            query({ idCliente, ruta }) {
                return {
                    url: '/clientes/' + idCliente + '/ruta/' + ruta,
                    method: 'DELETE',
                }
            }
        }),

        removeCliente: builder.mutation({
            query: (id) => ({
                url: 'clientes/' + id,
                method: 'DELETE'
            })
        }),
    })
})

export const {
    useGetPaginatedMutation,
    useGetClienteByIdMutation,
    useGetClienteMarcaMutation,
    useGetClienteColoresMutation,
    useGetClienteRutasMutation,
    useGetClienteFacturacionMutation,

    useUploadRutaMutation,
    useRemoveRutaMutation,

    useCreateClienteMutation,

    useUpdateClienteMarcaMutation,
    useUpdateClienteContactoMutation,
    useUpdateClienteColoresMutation,
    useUpdateClienteFacturacionMutation,

    useRemoveClienteMutation,
} = clientesApiSlice