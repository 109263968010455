import { tiendasApi } from '../api'

export const planesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getUsuariosPaginated: builder.mutation({
            query: credentials => ({
                url: 'usuarios/get-paginated',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        getAllUsuarios: builder.mutation({
            query: credentials => ({
                url: 'usuarios/all-con-acceso',
                method: 'GET',
            })
        }),
        getUsuario: builder.mutation({
            query: id => ({
                url: 'usuarios/' + id,
                method: 'GET'
            })
        }),
        createUsuario: builder.mutation({
            query: (body) => ({
                url: 'usuarios/create',
                method: 'POST',
                body: body
            })
        }),
        updateUsuario: builder.mutation({
            query: ({ body, id }) => ({
                url: 'usuarios/' + id,
                method: 'PUT',
                body: body
            })
        }),
        removeUsuario: builder.mutation({
            query: ({ idBorrar, idDerivar }) => ({
                url: 'usuarios/' + idBorrar + '/' + idDerivar,
                method: 'DELETE'
            })
        }),
    })
})

export const {
    useGetUsuariosPaginatedMutation,
    useGetAllUsuariosMutation,
    useGetUsuarioMutation,
    useCreateUsuarioMutation,
    useUpdateUsuarioMutation,
    useRemoveUsuarioMutation,
} = planesApiSlice