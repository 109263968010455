import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useGetClientesAgendasPaginatedMutation } from '../../../../store/api/endpoints/clientesagendasApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'

import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import FactCheckIcon from '@mui/icons-material/TurnedIn'

import EditIcon from '@mui/icons-material/Edit'
import PersonIcon from '@mui/icons-material/Person'

export default function ClientesAgendas() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const thead = []
    thead.push({ label: "ID", align: "right", ordenable: true })
    thead.push({ label: "Sucursal", align: "left", ordenable: true })
    thead.push({ label: "Nombre", align: "left", ordenable: true })
    thead.push({ label: "Visualiza Cliente", align: "center", ordenable: true })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "codigo" })
    tbody.push({ tipo: "texto", valor: "sucursal" })
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "verdaderofalso", valor: "visualiza_cliente" })
    let botones = []
    botones.push({ color: "primary", icono: <EditIcon />, ruta: "/clientes/" + urlParams.id + "/app/agendas/", valor: "codigo" })
    tbody.push({ tipo: "botones", valor: botones })


    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar las agendas.</p>)

    return (<>
        <Grid container>
            <Grid item xs={12} md={6} style={{ marginBottom: '0px', fontSize: '1.2rem' }}>
                <PersonIcon /> Agendas
            </Grid>
            <Grid item xs={12} md={6}>
                <div align="right">
                    {
                        permisosDelUsuario.indexOf(10) != -1 &&
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<AddIcon />}
                            to={"/clientes/" + urlParams.id + "/app/agendas/nueva"}
                        >
                            Nueva
                        </ButtonIcon>
                    }
                </div>
            </Grid>
        </Grid>
        <TablaPaginada
            api={useGetClientesAgendasPaginatedMutation}
            thead={thead}
            tbody={tbody}
            orderDefault={[2, 1]}
            extraParams={{idCliente: urlParams.id}}
        />
    </>)

}

