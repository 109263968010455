import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useGetClienteMarcaMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import DisplayInfoUl from '../../../elementos/DisplayInfoUl'


export default function ClienteMarcaGet() {

    const urlParams = useParams()
    const dispatch = useDispatch()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteMarcaMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar los clientes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <DisplayInfoUl
            titulo="Marca"
            editar={(permisosDelUsuario.indexOf(9) != -1 ? {
                path: '/clientes/' + urlParams.id + '/edit/marca'
            } : '')}
            data={
                [
                    { index: 'Marca', value: responseGetRegistro.marca },
                    { index: 'Email', value: responseGetRegistro.email },
                    { index: 'Cod. País', value: responseGetRegistro.tel_pais },
                    { index: 'Cod. Área', value: responseGetRegistro.tel_area },
                    { index: 'Ej Cel', value: responseGetRegistro.ej_cel },
                    { index: 'Whatsapp', value: responseGetRegistro.telefono },
                    { index: 'Direccion', value: responseGetRegistro.direccion },
                    { index: 'Mapa', value: responseGetRegistro.mapa },
                    { index: 'Instagram', value: responseGetRegistro.instagram },
                    { index: 'Facebook', value: responseGetRegistro.facebook },
                ]
            }
        />
    </>)

}

