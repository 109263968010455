import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useCreateClienteMutation } from '../../../store/api/endpoints/clientesApiSlice'
import { useGetAllUsuariosMutation } from '../../../store/api/endpoints/usuariosApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import TituloMini from '../../elementos/TituloMini'
import Select from '../../elementos/Select'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import HttpIcon from '@mui/icons-material/Http'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PublicIcon from '@mui/icons-material/Public'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import StoreIcon from '@mui/icons-material/Store'
import CheckIcon from '@mui/icons-material/Check'

export default function ClienteCrear() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getVendedores,
        {
            data: responseGetVendedoresAll,
            isUninitialized: isUninitializedGetVendedoresAll,
            isLoading: isLoadingGetVendedoresAll,
            isSuccess: isSuccessGetVendedoresAll,
            isError: isErrorGetVendedoresAll,
            error: errorDataGetVendedoresAll,
        }
    ] = useGetAllUsuariosMutation()

    const [
        crearRegistro,
        {
            data: responseCrearRegistro,
            isUninitialized: isUninitializedCrearRegistro,
            isLoading: isLoadingCrearRegistro,
            isSuccess: isSuccessCrearRegistro,
            isError: isErrorCrearRegistro,
            error: errorDataCrearRegistro,
        }
    ] = useCreateClienteMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            nombre,
            telefono,
            email,
            dominio,
            vendedor,
            tipo_app,
            marca: {
                marca: marca_marca,
                email: marca_email,
                ej_cel: marca_ejcel,
                telefono: marca_telefono,
                direccion: marca_direccion,
                mapa: marca_mapa,
                instagram: marca_instagram,
                facebook: marca_facebook,
                tel_pais: marca_telpais,
                tel_area: marca_telarea,
                lenguaje: lenguaje,
            },
        }
        crearRegistro(body)
    }

    useEffect(() => {
        getVendedores()
    }, [])

    useEffect(() => {

        if (isSuccessGetVendedoresAll) {
            setVendedores(responseGetVendedoresAll)
        }

    }, [isSuccessGetVendedoresAll])

    useEffect(() => {
        if (isErrorGetVendedoresAll) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetVendedoresAll.data.message
            }))
        }
    }, [isErrorGetVendedoresAll])

    useEffect(() => {
        if (isSuccessCrearRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))
            
            navigate("/clientes/" + responseCrearRegistro.id)
        }
    }, [isSuccessCrearRegistro])

    useEffect(() => {
        if (isErrorCrearRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCrearRegistro.data.message
            }))
        }
    }, [isErrorCrearRegistro])


    const [nombre, setNombre] = useState('')
    const [telefono, setTelefono] = useState('')
    const [email, setEmail] = useState('')
    const [dominio, setDominio] = useState('')
    const [vendedores, setVendedores] = useState([])
    const [vendedor, setVendedor] = useState('')
    const [lenguaje, setLenguaje] = useState('es_ar')
    const [tipo_app, setTipoApp] = useState('')
    const [marca_marca, setMarcaMarca] = useState('')
    const [marca_email, setMarcaEmail] = useState('')
    const [marca_ejcel, setMarcaEjCel] = useState('')
    const [marca_telefono, setMarcaTelefono] = useState('')
    const [marca_direccion, setMarcaDireccion] = useState('')
    const [marca_mapa, setMarcaMapa] = useState('')
    const [marca_instagram, setMarcaInstagram] = useState('')
    const [marca_facebook, setMarcaFacebook] = useState('')
    const [marca_telpais, setMarcaTelPais] = useState('549')
    const [marca_telarea, setMarcaTelArea] = useState('11')

    if (permisosDelUsuario.indexOf(9) == -1) return (<p>No tienes acceso a crear los clientes.</p>)

    if (isUninitializedGetVendedoresAll || isLoadingGetVendedoresAll || isLoadingCrearRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Typography color="text.primary">Crear</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Info de Contacto">Información de contacto de uso interna de doyTurnos.com</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Dominio"
                            icon={<HttpIcon />}
                            helperText="Sólo letras y números"
                            value={dominio}
                            onChange={setDominio}
                            type="text"
                            restricciones={['alfanumerico']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Nombre"
                            icon={<AccountCircle />}
                            helperText="Del contacto o dueño"
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Teléfono"
                            icon={<WhatsAppIcon />}
                            helperText="Del contacto o dueño"
                            value={telefono}
                            onChange={setTelefono}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Email"
                            icon={<MailOutlineIcon />}
                            helperText="Del contacto o dueño"
                            value={email}
                            onChange={setEmail}
                            type="email"
                            restricciones={['email']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Select
                            label="Vendedor"
                            icon={<PersonIcon />}
                            value={vendedor}
                            options={vendedores.map(function (e) {
                                return {
                                    value: e._id,
                                    label: e.nombre
                                }
                            })}
                            onChange={setVendedor}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br /><Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Tipo de Aplicación">Tipo de aplicación que va a utilizar el cliente.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} >
                        <Select
                            label="Tipo Aplicación"
                            icon={<PublicIcon />}
                            value={tipo_app}
                            options={[
                                {
                                    value: 'gym',
                                    label: 'Gym'
                                },
                                {
                                    value: 'eventos',
                                    label: 'Eventos'
                                },
                            ]}
                            onChange={setTipoApp}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Select
                            label="Lenguaje"
                            icon={<PublicIcon />}
                            value={lenguaje}
                            options={[
                                {
                                    value: 'es_ar',
                                    label: 'Argentina'
                                },
                                {
                                    value: 'es_cl',
                                    label: 'Chile'
                                }
                            ]}
                            onChange={setLenguaje}
                            type="text"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br /><Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Info de Marca">Información que visualizarán los clientes que deseen reservar un turno.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Nombre de Marca"
                            icon={<StoreIcon />}
                            value={marca_marca}
                            onChange={setMarcaMarca}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Email"
                            icon={<MailOutlineIcon />}
                            value={marca_email}
                            onChange={setMarcaEmail}
                            restricciones={['email']}
                            type="email"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Dirección"
                            icon={<LocationOnIcon />}
                            value={marca_direccion}
                            onChange={setMarcaDireccion}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Mapa"
                            icon={<LocationOnIcon />}
                            value={marca_mapa}
                            onChange={setMarcaMapa}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Ej. Cel"
                            icon={<WhatsAppIcon />}
                            helperText="Formato 11-3333-4444. Sin cel #."
                            value={marca_ejcel}
                            onChange={setMarcaEjCel}
                            type="tel"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Whatsapp para clientes"
                            icon={<WhatsAppIcon />}
                            helperText="Formato 5491133334444. Sin cel #."
                            value={marca_telefono}
                            onChange={setMarcaTelefono}
                            type="tel"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Código de País"
                            icon={<WhatsAppIcon />}
                            helperText="Formato 549"
                            value={marca_telpais}
                            onChange={setMarcaTelPais}
                            type="tel"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Código de Área"
                            icon={<WhatsAppIcon />}
                            helperText="Formato 11"
                            value={marca_telarea}
                            onChange={setMarcaTelArea}
                            type="tel"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Instagram"
                            icon={<InstagramIcon />}
                            helperText="Formato doyturnos. Sin insta #."
                            value={marca_instagram}
                            onChange={setMarcaInstagram}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Facebook"
                            icon={<FacebookIcon />}
                            helperText="Formato doyturnos. Sin face #."
                            value={marca_facebook}
                            onChange={setMarcaFacebook}
                            type="text"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>

    </>)

}

