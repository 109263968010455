import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useGetClienteRutasMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import DisplayInfoUl from '../../../elementos/DisplayInfoUl'


export default function ClienteRutasGet() {

    const urlParams = useParams()
    const dispatch = useDispatch()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteRutasMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar los clientes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <DisplayInfoUl
            titulo="Archivos e Imágenes"
            editar={(permisosDelUsuario.indexOf(9) != -1 ? {
                path: '/clientes/' + urlParams.id + '/edit/rutas'
            } : '')}
            data={
                [
                    { index: 'CSS', value: (responseGetRegistro.css === '' ? 'No' : 'Sí') },
                    { index: 'Logo', value: <img src={responseGetRegistro.logo} height="35" alt="Logo" style={{ marginLeft: '5px' }} /> },
                    { index: 'Socials', value: <img src={responseGetRegistro.socials} height="35" alt="Socials" style={{ marginLeft: '5px' }} /> },
                    { index: 'Favicon', value: <img src={responseGetRegistro.favicon} height="35" alt="Favicon" style={{ marginLeft: '5px' }} /> },
                    { index: 'Banner 1', value: <img src={responseGetRegistro.banner1} height="35" alt="Banner 1" style={{ marginLeft: '5px' }} /> },
                    { index: 'Banner 2', value: <img src={responseGetRegistro.banner2} height="35" alt="Banner 2" style={{ marginLeft: '5px' }} /> },
                ]
            }
        />
    </>)

}

