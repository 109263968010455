import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetPlanMutation } from '../../../store/api/endpoints/planesApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import Select from '../../elementos/Select'
import ButtonIcon from '../../elementos/ButtonIcon'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import EditIcon from '@mui/icons-material/Edit'
import FactCheckIcon from '@mui/icons-material/TurnedIn'
import CachedIcon from '@mui/icons-material/Cached'
import NumbersIcon from '@mui/icons-material/Numbers'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

export default function Plan() {

    const dispatch = useDispatch()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [nombre, setNombre] = useState('')
    const [importe, setImporte] = useState('')
    const [cant_agendas, setCantagendas] = useState('')
    const [periodo, setPeriodo] = useState('month')

    const [
        getPlan,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetPlanMutation()

    useEffect(() => {
        getPlan(urlParams.id)
    }, [])

    useEffect(() => {
        if (isSuccessGetRegistro) {
            setNombre(responseGetRegistro.nombre)
            setCantagendas(responseGetRegistro.cant_agendas)
            setImporte(responseGetRegistro.importe)
            setPeriodo(responseGetRegistro.periodo)
        }
    }, [isSuccessGetRegistro])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    if (permisosDelUsuario.indexOf(2) == -1) return (<p>No tienes acceso a visualizar los planes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/planes"}>
                        Planes
                    </Link>
                    <Typography color="text.primary">{nombre}</Typography>
                </Breadcrumbs>
            }
            icon={<FactCheckIcon />}
            end={<>
                {(permisosDelUsuario.indexOf(6) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    to={"/planes/" + urlParams.id + "/editar"}
                    marginRight
                >
                    Editar
                </ButtonIcon> : '')}
                {(permisosDelUsuario.indexOf(6) > -1 ? <ConfirmDelete
                    httpDelete={"planes/" + urlParams.id}
                    returnUrl={"/planes"}
                    variant="outlined"
                    size="small"
                >
                    Eliminar
                </ConfirmDelete> : '')}
            </>}
        />
        <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Cant. Agendas"
                        icon={<NumbersIcon />}
                        value={cant_agendas}
                        onChange={setCantagendas}
                        type="tel"
                        restricciones={['soloNumeros']}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Nombre del Plan"
                        icon={<FactCheckIcon />}
                        value={nombre}
                        onChange={setNombre}
                        type="text"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Importe"
                        icon={<AttachMoneyIcon />}
                        value={importe}
                        onChange={setImporte}
                        type="tel"
                        restricciones={['soloNumeros']}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Select
                        label="Período"
                        icon={<CachedIcon />}
                        value={periodo}
                        options={[{ value: 'month', label: 'Month' }]}
                        onChange={setPeriodo}
                        disabled
                    />
                </Grid>
            </Grid>
        </Container>

    </>)

}

