import React from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import '../../assets/css/Navbar.css';

import LogoHeader from '../elementos/LogoHeader';


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { logOut } from '../../store/slices/auth';
import { getUsuarioToken, getUsuarioNombre } from '../../store/slices/auth';
import { getStatusSidebar, changeStatusSidebar } from '../../store/slices/sidebar';



export default function Navbar() {

  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const statusSidebar = useSelector(getStatusSidebar)
  const token = useSelector(getUsuarioToken)
  const nombreUsuario = useSelector(getUsuarioNombre)

  const dispatch = useDispatch()

  const handleToggleMenu = () => {
    dispatch(changeStatusSidebar(!statusSidebar))
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  };

  const handleLogOut = () => {
    handleCloseUserMenu()
    dispatch(logOut())
  }

  const btnLeft = <Button onClick={handleToggleMenu}><MenuIcon /></Button>

  const btnRight =
    <>
      <Tooltip title="Configuración">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={nombreUsuario} src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Link to={"/mi-perfil"} onClick={handleCloseUserMenu}>
          <MenuItem >
            <Typography textAlign="center">Mi Perfil</Typography>
          </MenuItem>
        </Link>
        <MenuItem onClick={handleLogOut}>
          <Typography textAlign="center" >Salir</Typography>
        </MenuItem>
      </Menu>
    </>


  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            {(token ? btnLeft : '')}
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex' } }}
          >
            <LogoHeader />
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            {(token ? btnRight : '')}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
