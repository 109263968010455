import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation, useGetClienteFacturacionMutation, useUpdateClienteFacturacionMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetAfipCondicionivaMutation, useGetAfipDocumentosMutation } from '../../../../store/api/endpoints/afipCodigosApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import TituloMini from '../../../elementos/TituloMini'
import Select from '../../../elementos/Select'
import SwitchInput from '../../../elementos/SwitchInput'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'

export default function ClienteFacturacionUpdate() {

    const urlParams = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [activo, setActivo] = useState(false)
    const [tipo_documento, setTipoDocumento] = useState('')
    const [numero_documento, setNumeroDocumento] = useState('')
    const [condicion_iva, setCondicionIva] = useState('')
    const [razon_social, setRazonSocial] = useState('')
    const [condicion_venta, setCondicionVenta] = useState('')
    const [domicilio, setDomicilio] = useState('')
    const [provincia, setProvincia] = useState('')

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
            error: errorDataGetCliente,
        }
    ] = useGetClienteByIdMutation()

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteFacturacionMutation()

    const [
        getDocumentos,
        {
            data: responseGetDocumentos,
            isUninitialized: isUninitializedGetDocumentos,
            isLoading: isLoadingGetDocumentos,
            isSuccess: isSuccessGetDocumentos,
            isError: isErrorGetDocumentos,
            error: errorDataGetDocumentos,
        }
    ] = useGetAfipDocumentosMutation()

    const [
        getCondicioniva,
        {
            data: responseGetCondicioniva,
            isUninitialized: isUninitializedGetCondicioniva,
            isLoading: isLoadingGetCondicioniva,
            isSuccess: isSuccessGetCondicioniva,
            isError: isErrorGetCondicioniva,
            error: errorDataGetCondicioniva,
        }
    ] = useGetAfipCondicionivaMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateClienteFacturacionMutation()

    const handleSubmit = () => {

        let body = {
            activo,
            tipo_documento,
            numero_documento,
            condicion_iva,
            razon_social,
            condicion_venta,
            domicilio,
            provincia,
        }

        updateRegistro({ id: urlParams.id, body })
    }

    useEffect(() => {
        getCliente(urlParams.id)
        getDocumentos()
        getCondicioniva()
    }, [])

    useEffect(() => {
        if (isErrorGetDocumentos) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetDocumentos.data.message
            }))
        }
    }, [isErrorGetDocumentos])

    useEffect(() => {
        if (isSuccessGetDocumentos) {
            getRegistro(urlParams.id)
        }
    }, [isSuccessGetDocumentos])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorGetCliente) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetCliente.data.message
            }))
        }
    }, [isErrorGetCliente])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

            navigate("/clientes/" + urlParams.id)
        }
    }, [isSuccessUpdateRegistro])

    useEffect(() => {

        if (isSuccessGetRegistro) {

            setActivo(responseGetRegistro.activo)
            setTipoDocumento(responseGetRegistro.tipo_documento)
            setNumeroDocumento(responseGetRegistro.numero_documento)
            setCondicionIva(responseGetRegistro.condicion_iva)
            setCondicionVenta(responseGetRegistro.condicion_venta)
            setRazonSocial(responseGetRegistro.razon_social)
            setDomicilio(responseGetRegistro.domicilio)
            setProvincia(responseGetRegistro.provincia)

        }

    }, [isSuccessGetRegistro])

    if (permisosDelUsuario.indexOf(9) == -1) return (<p>No tienes acceso a modificar los clientes.</p>)

    if (isUninitializedGetCliente || isLoadingGetCliente || isUninitializedGetCondicioniva || isLoadingGetCondicioniva || isUninitializedGetDocumentos || isLoadingGetDocumentos || isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetCliente.dominio}
                    </Link>
                    <Typography color="text.primary">Editar Contacto</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Facturación">Indica si se le hace factura al cliente cada vez que realiza el pago del plan.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} >
                        <SwitchInput
                            label="Activo"
                            value={activo}
                            onChange={setActivo}
                            helperText="¿Se hace factura?"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} ></Grid>
                    <Grid item xs={12} md={3} >
                        <Select
                            label="Tipo Documento"
                            value={tipo_documento}
                            options={responseGetDocumentos.map(elem => {
                                return {
                                    value: elem.codigo,
                                    label: elem.descripcion,
                                }
                            })}
                            onChange={setTipoDocumento}
                            required={activo}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="N° Documento"
                            value={numero_documento}
                            onChange={setNumeroDocumento}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required={activo}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Select
                            label="Condición IVA"
                            value={condicion_iva}
                            options={responseGetCondicioniva.map(elem => {
                                return {
                                    value: elem.codigo,
                                    label: elem.descripcion,
                                }
                            })}
                            onChange={setCondicionIva}
                            required={activo}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Razón Social"
                            value={razon_social}
                            onChange={setRazonSocial}
                            type="text"
                            required={activo}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Condición de Venta"
                            value={condicion_venta}
                            onChange={setCondicionVenta}
                            type="text"
                            helperText="Colocar 'Contado'"
                            required={activo}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Domicilio"
                            value={domicilio}
                            onChange={setDomicilio}
                            type="text"
                            helperText="Calle y Número"
                            required={activo}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Select
                            label="Provincia"
                            value={provincia}
                            options={[
                                {
                                    value: 'Ciudad de Buenos Aires',
                                    label: 'Ciudad de Buenos Aires'
                                },
                                {
                                    value: 'Buenos Aires',
                                    label: 'Buenos Aires'
                                },
                                {
                                    value: 'Catamarca',
                                    label: 'Catamarca'
                                },
                                {
                                    value: 'Chaco',
                                    label: 'Chaco'
                                },
                                {
                                    value: 'Chubut',
                                    label: 'Chubut'
                                },
                                {
                                    value: 'Córdoba',
                                    label: 'Córdoba'
                                },
                                {
                                    value: 'Corrientes',
                                    label: 'Corrientes'
                                },
                                {
                                    value: 'Entre Ríos',
                                    label: 'Entre Ríos'
                                },
                                {
                                    value: 'Formosa',
                                    label: 'Formosa'
                                },
                                {
                                    value: 'Jujuy',
                                    label: 'Jujuy'
                                },
                                {
                                    value: 'La Pampa',
                                    label: 'La Pampa'
                                },
                                {
                                    value: 'La Rioja',
                                    label: 'La Rioja'
                                },
                                {
                                    value: 'Mendoza',
                                    label: 'Mendoza'
                                },
                                {
                                    value: 'Misiones',
                                    label: 'Misiones'
                                },
                                {
                                    value: 'Neuquén',
                                    label: 'Neuquén'
                                },
                                {
                                    value: 'Río Negro',
                                    label: 'Río Negro'
                                },
                                {
                                    value: 'Salta',
                                    label: 'Salta'
                                },
                                {
                                    value: 'San Juan',
                                    label: 'San Juan'
                                },
                                {
                                    value: 'San Luis',
                                    label: 'San Luis'
                                },
                                {
                                    value: 'Santa Cruz',
                                    label: 'Sana Cruz'
                                },
                                {
                                    value: 'Santa Fe',
                                    label: 'Santa Fe'
                                },
                                {
                                    value: 'Santiago del Estero',
                                    label: 'Santiago del Estero'
                                },
                                {
                                    value: 'Tierra del Fuego',
                                    label: 'Tierra del Fuego'
                                },
                                {
                                    value: 'Tucumán',
                                    label: 'Tucumán'
                                }
                            ]}
                            onChange={setProvincia}
                            required={activo}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

