import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../store/api/endpoints/clientesApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import DisplayInfoUl from '../../elementos/DisplayInfoUl'
import ButtonIcon from '../../elementos/ButtonIcon'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import ClienteMarcaGet from './modulos/ClienteMarcaGet'
import ClienteColoresGet from './modulos/ClienteColoresGet'
import ClienteRutasGet from './modulos/ClienteRutasGet'
import ClienteFacturacionGet from './modulos/ClienteFacturacionGet'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SettingsIcon from '@mui/icons-material/Settings'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt'

export default function ClienteDetalle() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])


    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar clientes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Typography color="text.primary">{responseGetRegistro.nombre}</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
            end={
                <>
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<SettingsIcon />}
                        to={'/clientes/' + urlParams.id}
                        marginRight
                    />
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="success"
                        startIcon={<PointOfSaleIcon />}
                        to={'/clientes/' + urlParams.id + '/cuenta-corriente'}
                        marginRight
                    />
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<AppSettingsAltIcon />}
                        to={'/clientes/' + urlParams.id + '/app'}
                        marginRight
                    />
                    {
                        permisosDelUsuario.indexOf(11) != -1 &&
                        <ConfirmDelete
                            variant="contained"
                            size="small"
                            httpDelete={"cliente/" + urlParams.id}
                            returnUrl={"/clientes/"}
                            soloIcono
                        />
                    }
                </>
            }
        />
        <Container component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} >
                    <DisplayInfoUl
                        titulo="Info de Contacto"
                        editar={(permisosDelUsuario.indexOf(9) != -1 ? {
                            path: '/clientes/' + urlParams.id + '/edit/contacto'
                        } : '')}
                        data={
                            [
                                { index: 'Nombre', value: responseGetRegistro.nombre },
                                { index: 'Email', value: responseGetRegistro.email },
                                { index: 'Teléfono', value: responseGetRegistro.telefono },
                                { index: 'Vendedor', value: responseGetRegistro.vendedor }
                            ]
                        }
                    />
                </Grid>
                <Grid item xs={12} md={8} >
                    <DisplayInfoUl
                        titulo="Acceso"
                        data={
                            [
                                { index: 'Alta', value: responseGetRegistro.alta },
                                { index: 'URL Clientes', value: responseGetRegistro.url_clientes },
                                { index: 'URL Admin', value: responseGetRegistro.url_admin },
                            ]
                        }
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <hr />
                    <h3 align="center" >Vista de Clientes</h3>
                </Grid>


                <Grid item xs={12} md={4} >
                    <ClienteMarcaGet />
                </Grid>

                <Grid item xs={12} md={4} >
                    <ClienteColoresGet />
                </Grid>

                <Grid item xs={12} md={4} >
                    <ClienteRutasGet />
                </Grid>

                <Grid item xs={12} md={12} >
                    <hr />
                    <h3 align="center" >Facturación</h3>
                </Grid>

                <Grid item xs={12} md={4} >
                    <ClienteFacturacionGet />
                </Grid>


            </Grid>
        </Container>

    </>)

}

