import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useGetPlanesPaginatedMutation } from '../../../store/api/endpoints/planesApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../elementos/ButtonIcon'
import HeaderVista from '../../elementos/HeaderVista'
import TablaPaginada from '../../elementos/TablaPaginada'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import FactCheckIcon from '@mui/icons-material/TurnedIn'

import SearchIcon from '@mui/icons-material/Search'


export default function Planes() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const thead = []
    thead.push({ label: "Agendas", align: "right", ordenable: true })
    thead.push({ label: "Nombre", align: "left", ordenable: true })
    thead.push({ label: "Período", align: "left", ordenable: true })
    thead.push({ label: "Importe", align: "right", ordenable: true })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "cantidad" })
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "texto", valor: "periodo" })
    tbody.push({ tipo: "precio", options: [",", ".", "$ "], valor: "importe" })
    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/planes/", valor: "id" })
    tbody.push({ tipo: "botones", valor: botones })


    if (permisosDelUsuario.indexOf(2) == -1) return (<p>No tienes acceso a visualizar los planes.</p>)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.primary">Planes</Typography>
                </Breadcrumbs>
            }
            icon={<FactCheckIcon />}
            end={(permisosDelUsuario.indexOf(6) > -1 ? <ButtonIcon
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                to="/planes/crear"
            >
                Nuevo
            </ButtonIcon> : '')}
        />
        <TablaPaginada
            api={useGetPlanesPaginatedMutation}
            thead={thead}
            tbody={tbody}
            orderDefault={[0,0]}
        />
    </>)

}

