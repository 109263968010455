import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useGetPermisosAppPaginatedMutation } from '../../../store/api/endpoints/permisosAppApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'


import ButtonIcon from '../../elementos/ButtonIcon'
import HeaderVista from '../../elementos/HeaderVista'
import TablaPaginada from '../../elementos/TablaPaginada'
import BotonDesplegable from '../../elementos/BotonDesplegable'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import LockOpenIcon from '@mui/icons-material/LockOpen'

import SearchIcon from '@mui/icons-material/Search'


export default function PermisosApp() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const urlParams = useParams()

    

    

    
    const thead = []
    thead.push({ label: "Codigo", align: "right", ordenable: true })
    thead.push({ label: "Nombre", align: "left", ordenable: true })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "codigo" })
    tbody.push({ tipo: "texto", valor: "nombre" })
    let botones = []
    botones.push({ color: "primary", icono: <EditIcon />, ruta: "/permisos/app/" + urlParams.app + "/editar/", valor: "_id" })
    tbody.push({ tipo: "botones", valor: botones })



    if (permisosDelUsuario.indexOf(14) == -1) return (<p>No tienes acceso a visualizar los permisos.</p>)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.primary">Permisos App</Typography>
                    <Typography color="text.primary">
                        <BotonDesplegable 
                            botonTitulo={urlParams.app}
                            options={[
                                {titulo: 'GYM', url: "/permisos/app/gym"},
                                {titulo: 'Evento', url: "/permisos/app/evento"},
                                {titulo: 'Eventos', url: "/permisos/app/eventos"},
                            ]}
                        />
                    </Typography>
                </Breadcrumbs>
            }
            icon={<LockOpenIcon />}
            end={<ButtonIcon
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                to={"/permisos/app/" + urlParams.app + "/crear"}
            >
                Nuevo
            </ButtonIcon>}
        />
        <TablaPaginada
            api={useGetPermisosAppPaginatedMutation}
            thead={thead}
            tbody={tbody}
            orderDefault={[0, 0]}
            extraParams={{ app: urlParams.app }}
        />
    </>)

}

