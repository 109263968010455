import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetPermisoBackofficeMutation, useUpdatePermisoBackofficeMutation } from '../../../store/api/endpoints/permisosBackofficeApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import ButtonIcon from '../../elementos/ButtonIcon'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import CachedIcon from '@mui/icons-material/Cached'
import NumbersIcon from '@mui/icons-material/Numbers'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

export default function PermisoBackoffice() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetPermisoBackofficeMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdatePermisoBackofficeMutation()

    const handleSubmit = (e) => {

        e.preventDefault()

        let body = {
            codigo,
            nombre
        }

        updateRegistro({ body, id: urlParams.id })

    }

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isSuccessGetRegistro) {

            setCodigo(responseGetRegistro.codigo)
            setNombre(responseGetRegistro.nombre)

        }
    }, [isSuccessGetRegistro])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/permisos/backoffice/" + urlParams.id)

        }
    }, [isSuccessUpdateRegistro])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    const [codigo, setCodigo] = useState('')
    const [nombre, setNombre] = useState('')

    if (permisosDelUsuario.indexOf(5) == -1) return (<p>No tienes acceso a visualizar los permisos.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/permisos/backoffice"}>
                        Permisos Backoffice
                    </Link>
                    <Link underline="hover" color="inherit" to={"/permisos/backoffice/" + urlParams.id}>
                        {nombre}
                    </Link>
                    <Typography color="text.primary">Editar</Typography>
                </Breadcrumbs>
            }
            icon={<LockOpenIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Codigo"
                            icon={<NumbersIcon />}
                            value={codigo}
                            onChange={setCodigo}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Nombre"
                            icon={<LockOpenIcon />}
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

