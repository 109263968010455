import { tiendasApi } from '../api'

export const clientesPermisosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientesPermisosPaginated: builder.mutation({
            query: ({ extraParams, body }) => ({
                url: 'permisos/' + extraParams.tipoApp + '/get-paginated',
                method: 'POST',
                body: body
            })
        }),
    })
})

export const {
    useGetClientesPermisosPaginatedMutation,
} = clientesPermisosApiSlice