import React from 'react';



export default function LogoHeader() {

    let today = new Date();
    let periodo = (today.getMonth() + 1) + '' + today.getDate();

    if (periodo >= 1208) {

        return (
            <img style={{ paddingTop: '7px' }} height="75" alt="doyturnos.com" src="https://cdn.doyturnos.com.ar/images-adm/logo_navidad.jpg" />
        )

    } else {

        return (
            <img style={{ paddingTop: '7px' }} height="75" alt="doyturnos.com" src="https://cdn.doyturnos.com.ar/images-adm/logo.jpg" />
        )

    }


}