import { tiendasApi } from '../api'

export const clientesagendasApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientesAgendasPaginated: builder.mutation({
            query: ({ extraParams, body }) => ({
                url: 'clientes/app/gym/' + extraParams.idCliente + '/agendas/paginated',
                method: 'POST',
                body: body
            })
        }),
        getClienteAgenda: builder.mutation({
            query: ({ idCliente, id }) => ({
                url: 'clientes/app/gym/' + idCliente + '/agendas/' + id,
                method: 'GET'
            })
        }),
        createClienteAgenda: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/agendas',
                method: 'POST',
                body: body
            })
        }),
        updateClienteAgenda: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/agendas',
                method: 'PUT',
                body: body
            })
        }),
        removeClienteAgenda: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/agendas',
                method: 'DELETE',
                body: body
            })
        }),
    })
})

export const {
    useGetClientesAgendasPaginatedMutation,
    useGetClienteAgendaMutation,
    useUpdateClienteAgendaMutation,
    useCreateClienteAgendaMutation,
    useRemoveClienteAgendaMutation,
} = clientesagendasApiSlice