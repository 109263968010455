import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../slices/auth'

const baseQuery = fetchBaseQuery({
  baseUrl: (window.location.href.includes("doyturnos.com") ? 'https://api-app.doyturnos.com/api/adm/' : 'http://localhost:3800/api/adm/' ),
  //credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token
    if (token) {
      headers.set("Authorization", token)
    }
    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if(result?.error?.status === 403){
    //send refresh token to get new access token
    const refreshResult = await baseQuery('self/refresh-token', api, extraOptions)
    if(refreshResult?.data){
      const user = api.getState().auth.user
      //store the new token
      api.dispatch(setCredentials({...refreshResult.data, user}))
      //retry the original query with new access token
      result = await baseQuery(args, api, extraOptions)
    }else{
      api.dispatch(logOut())
    }
  }

  return result

}

export const tiendasApi = createApi({
  reducerPath: 'tiendasApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 20,
  endpoints: builder => ({}),
})


/* Endpoints para AFIP */

const baseQueryAfip = fetchBaseQuery({
  baseUrl: (window.location.href.includes("doyturnos.com") ? 'https://eventos-api.doyturnos.com/api/afip-codigos/' : 'http://localhost:3800/api/afip-codigos/' ),
  //credentials: 'include',
})

export const afipCodigosApi = createApi({
  reducerPath: 'afipCodigosApi',
  baseQuery: baseQueryAfip,
  keepUnusedDataFor: 20,
  endpoints: builder => ({}),
})