import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetAllPlanesMutation } from '../../../../store/api/endpoints/planesApiSlice'
import { useCreatePlanclienteMutation } from '../../../../store/api/endpoints/clientesplanesApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Select from '../../../elementos/Select'
import TituloMini from '../../../elementos/TituloMini'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import FactCheckIcon from '@mui/icons-material/TurnedIn'

export default function ClientePlanesNevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [planElegido, setPlanElegido] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    const [
        getPlanes,
        {
            data: responseGetPlanes,
            isUninitialized: isUninitializedGetPlanes,
            isLoading: isLoadingGetPlanes,
            isSuccess: isSuccessGetPlanes,
            isError: isErrorGetPlanes,
            error: errorDataGetPlanes,
        }
    ] = useGetAllPlanesMutation()

    const [
        crearRegistro,
        {
            data: responseCreatePlan,
            isUninitialized: isUninitializedCreatePlan,
            isLoading: isLoadingCreatePlan,
            isSuccess: isSuccessCreatePlan,
            isError: isErrorCreatePlan,
            error: errorDataCreatePlan,
        }
    ] = useCreatePlanclienteMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            cliente: urlParams.id,
            plan: planElegido,
        }
        crearRegistro(body)
    }


    useEffect(() => {
        getRegistro(urlParams.id)
        getPlanes()
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorGetPlanes) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetPlanes.data.message
            }))
        }
    }, [isErrorGetPlanes])

    useEffect(() => {
        if (isErrorCreatePlan) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreatePlan.data.message
            }))
        }
    }, [isErrorCreatePlan])

    useEffect(() => {
        if (isSuccessCreatePlan) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡El plan se ha creado!"
            }))

            navigate("/clientes/" + urlParams.id + "/cuenta-corriente")
        }
    }, [isSuccessCreatePlan])

    if (permisosDelUsuario.indexOf(10) == -1) return (<p>No tienes acceso a modificar clientes.</p>)

    if (isUninitializedGetPlanes || isLoadingGetPlanes || isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingCreatePlan) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/cuenta-corriente"}>
                        Cuenta Corriente
                    </Link>
                    <Typography color="text.primary">Nuevo Plan</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
        <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Plan Asociado">Indica el plan asociado al cliente.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Select
                            label="Plan"
                            icon={<FactCheckIcon />}
                            value={planElegido}
                            options={responseGetPlanes.map(function (e) {
                                return {
                                    value: e.id,
                                    label: e.nombre
                                }
                            })}
                            onChange={setPlanElegido}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

