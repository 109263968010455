import { useState, useEffect } from 'react'

import { NumericFormat } from 'react-number-format'
import Moment from 'react-moment'

import Loading from './Loading'
import Input from './Input'

import Autocomplete from '@mui/material/Autocomplete'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'

import '../../assets/css/table.css'

import SearchIcon from '@mui/icons-material/Search'
import CircleIcon from '@mui/icons-material/Circle'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'

const delay = 0.4

export default function AutocompletarMauri({
    api,
    orderDefault = [0, 0],
    extraParams,
    optionsEstructura,//Recibe [ 'id', ['label', 'otro label'], ['sulabel','otrosublabel'], ['separador'] ]
    icon,
    helperText,
    size,
    label,
    value,
    setValue,
    autoFocus,
    autoComplete,
    disabled,
    required,
    type,
    error,
}) {

    const [page, setPage] = useState(1)
    const [textsearch, setTextsearch] = useState("")
    const [order, setOrder] = useState(orderDefault)
    const [inputVisualiza, setInputVisualiza] = useState(null)

    const [
        getRegistros,
        {
            data: response,
            isUninitialized,
            isLoading,
            isSuccess,
            isError,
        }
    ] = api()

    const transformarDoc = (unDoc) => {

        let idFinal = unDoc[optionsEstructura[0]]

        let labelFinal = ''
        optionsEstructura[1].forEach((element, index) => {
            if (index > 0) labelFinal += optionsEstructura[3]
            labelFinal += unDoc[element]
        })

        return {
            id: idFinal,
            label: labelFinal,
        }

    }

    const eligeOpcion = (valor) => {
        if (valor) {
            setValue(valor.id)
            setInputVisualiza(valor)
        } else {
            setValue(null)
            setInputVisualiza(null)
        }
    }

    const eligeOpcionConId = (id) => {
        let getIds = response.docs.map(elem => elem[optionsEstructura[0]])
        let pos = getIds.indexOf(id)
        if (pos >= 0) eligeOpcion(transformarDoc(response.docs[pos]))
    }

    useEffect(() => {

        let timer1 = setTimeout(() => {

            getRegistros({
                extraParams: extraParams,
                body: { page, textsearch, order },
            })

        }, delay * 1000)

        return () => {
            clearTimeout(timer1)
        }

    }, [textsearch])

    useEffect(() => {

        getRegistros({
            extraParams: extraParams,
            body: { page, textsearch, order },
        })

    }, [page, order])

    useEffect(() => {
        if (isSuccess) {
            if (value != "") {
                eligeOpcionConId(value)
            }
        }
    }, [isSuccess])



    if (isLoading || isUninitialized) return (<Loading />)

    return (
        <Autocomplete
            options={response.docs.map(elem => (transformarDoc(elem)))}
            loading={true}
            loadingText="Cargando..."
            onChange={(event, tempVal) => eligeOpcion(tempVal)}
            value={inputVisualiza}
            isOptionEqualToValue={(option, valTemp) => option.id === valTemp.id}
            renderInput={(params) =>
                <TextField
                    {...params}
                    sx={{
                        color: 'red',
                        marginTop: '15px'
                    }}

                    size={(size ? size : '')}
                    label={label}
                    helperText={"Mostrando " + response.docs.length + " opciones de " + response.totalDocs}
                    fullWidth
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    required={required}
                    type={type}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                {icon}
                            </InputAdornment>
                        ),
                    }}
                />}
        />)

    /* return (
         <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
             <Input
                 icon={icon}
                 helperText={helperText}
                 onChange={(e) => setTextsearch(e.target.value)}
                 variant="standard"
                 size={(size ? size : '')}
                 label={label}
                 autoFocus={autoFocus}
                 autoComplete={autoComplete}
                 disabled={disabled}
                 required={required}
                 type={type} 
                 error={error || error}
             />
         </Box>
     )*/

}