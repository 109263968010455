import React from 'react'

import HomeIcon from '@mui/icons-material/Home'
import AutoAwesomeMotionIcon from '@mui/icons-material/LocalCafe'
import SupportAgentIcon from '@mui/icons-material/Store'
import FactCheckIcon from '@mui/icons-material/TurnedIn'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CloseIcon from '@mui/icons-material/Close'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockPersonIcon from '@mui/icons-material/LockPerson'

export const SidebarIcono = ({ codigo }) => {

    if (codigo == 1) return (<HomeIcon fontSize="small" />)
    if (codigo == 2) return (<AutoAwesomeMotionIcon fontSize="small" />)
    if (codigo == 3) return (<SupportAgentIcon fontSize="small" />)
    if (codigo == 4) return (<LockOpenIcon fontSize="small" />)
    if (codigo == 5) return (<PeopleAltIcon fontSize="small" />)
    if (codigo == 6) return (<LockPersonIcon fontSize="small" />)



    return (<CloseIcon />)

}
