import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetClientesSucursalesPaginatedMutation } from '../../../../store/api/endpoints/clientessucusalesApiSlice'
import { useGetClienteAgendaMutation, useUpdateClienteAgendaMutation } from '../../../../store/api/endpoints/clientesagendasApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import Input from '../../../elementos/Input'
import AutocompletarMauri from '../../../elementos/AutocompletarMauri'
import Select from '../../../elementos/Select'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import PersonIcon from '@mui/icons-material/Person'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PlaceIcon from '@mui/icons-material/Place'

export default function ClienteAgendasEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [sucursal, setSucursal] = useState('')
    const [nombre, setNombre] = useState('')
    const [telefono, setTelefono] = useState('')
    const [correo, setCorreo] = useState('')
    const [visualiza_cliente, setVisualizaCliente] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    const [
        getAgenda,
        {
            data: responseGetAgenda,
            isUninitialized: isUninitializedGetAgenda,
            isLoading: isLoadingGetAgenda,
            isSuccess: isSuccessGetAgenda,
            isError: isErrorGetAgenda,
            error: errorDataGetAgenda,
        }
    ] = useGetClienteAgendaMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateClienteAgendaMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            codigo: urlParams.idAgenda,
            nombre,
            sucursal,
            visualiza_cliente,
        }
        updateRegistro({ idCliente: urlParams.id, body })
    }


    useEffect(() => {
        getAgenda({ idCliente: urlParams.id, id: urlParams.idAgenda })
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorGetAgenda) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetAgenda.data.message
            }))
        }
    }, [isErrorGetAgenda])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    useEffect(() => {
        if (isSuccessGetAgenda) {
            setSucursal(responseGetAgenda.sucursal)
            setNombre(responseGetAgenda.nombre)
            setVisualizaCliente(responseGetAgenda.visualiza_cliente)
        }
    }, [isSuccessGetAgenda])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡La agenda se ha actualizado!"
            }))

            navigate("/clientes/" + urlParams.id + "/app")
        }
    }, [isSuccessUpdateRegistro])

    if (permisosDelUsuario.indexOf(10) == -1) return (<p>No tienes acceso a editar agendas.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/app"}>
                        App
                    </Link>
                    <Typography color="text.primary">Editar Agenda</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
            end={<ConfirmDelete
                variant="contained"
                size="small"
                httpDelete={"clientes/app/gym/" + urlParams.id + "/agendas/" + urlParams.idAgenda}
                returnUrl={"/clientes/" + urlParams.id + "/app"}
                soloIcono
            />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                <Grid item xs={12} md={4} >
                        <AutocompletarMauri
                            label="Sucursal"
                            api={useGetClientesSucursalesPaginatedMutation}
                            orderDefault={[0, 0]}
                            extraParams={{idCliente: urlParams.id}}
                            optionsEstructura = {['codigo', ['barrio','nombre'], [], ' - ']}
                            icon={<PlaceIcon />}
                            value={sucursal}
                            setValue={setSucursal}
                            required
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Input
                            label="Nombre"
                            icon={<PersonIcon />}
                            helperText="Ej: Liniers"
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <Select
                            label="Visualiza Cliente"
                            icon={<RemoveRedEyeIcon />}
                            value={visualiza_cliente}
                            options={[
                                { value: 0, label: 'No' },
                                { value: 1, label: 'Sí' }
                            ]}
                            onChange={setVisualizaCliente}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

