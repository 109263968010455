import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetClientesAgendasPaginatedMutation } from '../../../../store/api/endpoints/clientesagendasApiSlice'
import { useGetClientesPermisosPaginatedMutation } from '../../../../store/api/endpoints/clientesPermisosApiSlice'
import { useCreateClienteLoginMutation } from '../../../../store/api/endpoints/clientesLoginsApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button, Divider } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Input from '../../../elementos/Input'
import CheckboxList from '../../../elementos/CheckboxList'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import EmailIcon from '@mui/icons-material/Email'
import PersonIcon from '@mui/icons-material/Person'
import PasswordIcon from '@mui/icons-material/Password'
import LockPersonIcon from '@mui/icons-material/LockPerson'

export default function ClienteLoginsNuevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [nombre, setNombre] = useState('')
    const [usuario, setUsuario] = useState('')
    const [contrasena, setContrasena] = useState('')
    const [controla, setControla] = useState('')
    const [permisos, setPermisos] = useState('')
    const [email, setEmail] = useState('')
    const [agendasTotales, setAgendasTotales] = useState([])
    const [permisosTotales, setPermisosTotales] = useState([])

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    const [
        getAgendas,
        {
            data: responseGetAgendas,
            isUninitialized: isUninitializedGetAgendas,
            isLoading: isLoadingGetAgendas,
            isSuccess: isSuccessGetAgendas,
            isError: isErrorGetAgendas,
            error: errorDataGetAgendas,
        }
    ] = useGetClientesAgendasPaginatedMutation()

    const [
        getPermisos,
        {
            data: responseGetPermisos,
            isUninitialized: isUninitializedGetPermisos,
            isLoading: isLoadingGetPermisos,
            isSuccess: isSuccessGetPermisos,
            isError: isErrorGetPermisos,
            error: errorDataGetPermisos,
        }
    ] = useGetClientesPermisosPaginatedMutation()

    const [
        crearRegistro,
        {
            data: responseCreateRegistro,
            isUninitialized: isUninitializedCreateRegistro,
            isLoading: isLoadingCreateRegistro,
            isSuccess: isSuccessCreateRegistro,
            isError: isErrorCreateRegistro,
            error: errorDataCreateRegistro,
        }
    ] = useCreateClienteLoginMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            nombre,
            usuario,
            contrasena,
            controla,
            permisos,
            email,
        }
        crearRegistro({ idCliente: urlParams.id, body })
    }


    useEffect(() => {
        getRegistro(urlParams.id)
        getAgendas({
            extraParams: { idCliente: urlParams.id },
            body: { page: 1, textsearch: '', order: [1, 0], itemsPerPage: 99999 },
        })
    }, [])

    useEffect(() => {
        if (isSuccessGetRegistro) {
            getPermisos({
                extraParams: { tipoApp: responseGetRegistro.tipo_app },
                body: { page: 1, textsearch: '', order: [1, 0], itemsPerPage: 99999 },
            })
        }
    }, [isSuccessGetRegistro])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorCreateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreateRegistro.data.message
            }))
        }
    }, [isErrorCreateRegistro])

    useEffect(() => {
        if (isSuccessGetAgendas) {
            setAgendasTotales(responseGetAgendas.docs)
        }
    }, [isSuccessGetAgendas])

    useEffect(() => {
        if (isErrorGetAgendas) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetAgendas.data.message
            }))
        }
    }, [isErrorGetAgendas])

    useEffect(() => {
        if (isSuccessGetPermisos) {
            setPermisosTotales(responseGetPermisos.docs)
        }
    }, [isSuccessGetPermisos])

    useEffect(() => {
        if (isErrorGetPermisos) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetPermisos.data.message
            }))
        }
    }, [isErrorGetPermisos])

    useEffect(() => {
        if (isSuccessCreateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡El usuario se ha creado!"
            }))

            navigate("/clientes/" + urlParams.id + "/app")
        }
    }, [isSuccessCreateRegistro])

    if (permisosDelUsuario.indexOf(10) == -1) return (<p>No tienes acceso a crear usuarios.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isUninitializedGetAgendas || isLoadingGetAgendas || isUninitializedGetPermisos || isLoadingGetPermisos || isLoadingCreateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/app"}>
                        App
                    </Link>
                    <Typography color="text.primary">Nuevo Usuario</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Nombre"
                            icon={<PersonIcon />}
                            helperText="Ej: Trini"
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Usuario"
                            icon={<PersonIcon />}
                            helperText="Ej: trini"
                            value={usuario}
                            onChange={setUsuario}
                            type="text"
                            restricciones={['alfanumerico']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Email"
                            icon={<EmailIcon />}
                            helperText="Ej: trini@gmail.com"
                            value={email}
                            onChange={setEmail}
                            type="email"
                            restricciones={['email']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Contraseña"
                            icon={<PasswordIcon />}
                            value={contrasena}
                            onChange={setContrasena}
                            type="password"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} ><Divider /></Grid>
                    <Grid item xs={12} md={9} >

                    </Grid>
                    <Grid item xs={12} md={6} >
                        <CheckboxList
                            titulo="Acceso Agendas"
                            icon={<PersonIcon />}
                            elegidos={controla}
                            opciones={agendasTotales.map(elem => ({
                                codigo: elem.codigo,
                                nombre: elem.nombre,
                            }))}
                            handleChange={setControla}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <CheckboxList
                            titulo="Permisos"
                            icon={<LockPersonIcon />}
                            elegidos={permisos}
                            opciones={permisosTotales.map(elem => ({
                                codigo: elem.codigo,
                                nombre: elem.nombre,
                            }))}
                            handleChange={setPermisos}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

