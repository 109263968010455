import { tiendasApi } from '../api'

export const planesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPlanesPaginated: builder.mutation({
            query: ({body: body}) => ({
                url: 'planes/get-paginated',
                method: 'POST',
                body: body
            })
        }),
        getPlan: builder.mutation({
            query: id => ({
                url: 'planes/' + id,
                method: 'GET'
            })
        }),
        getAllPlanes: builder.mutation({
            query: () => ({
                url: 'planes',
                method: 'GET'
            })
        }),
        createPlan: builder.mutation({
            query: (body) => ({
                url: 'planes',
                method: 'POST',
                body: body
            })
        }),
        updatePlan: builder.mutation({
            query: ({ body, id }) => ({
                url: 'planes/' + id,
                method: 'PUT',
                body: body
            })
        }),
        removePlan: builder.mutation({
            query: id => ({
                url: 'planes/' + id,
                method: 'DELETE'
            })
        }),
    })
})

export const {
    useGetPlanesPaginatedMutation,
    useGetPlanMutation,
    useGetAllPlanesMutation,
    useCreatePlanMutation,
    useUpdatePlanMutation,
    useRemovePlanMutation,
} = planesApiSlice