import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { NumericFormat } from 'react-number-format'
import Moment from 'react-moment'

import { useGetPlanVigenteMutation } from '../../../../store/api/endpoints/clientesplanesApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Loading from '../../../elementos/Loading'
import ButtonIcon from '../../../elementos/ButtonIcon'

import Grid from '@mui/material/Grid'

import AddIcon from '@mui/icons-material/Add'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'


export default function ClientePlanes() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetPlanVigenteMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])


    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar clientes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <Grid container>
            <Grid item xs={12} md={6} style={{ marginBottom: '0px', fontSize: '1.2rem' }}>
                <AutoAwesomeMotionIcon /> Planes Activos
            </Grid>
            <Grid item xs={12} md={6}>
                <div align="right">
                    {
                        permisosDelUsuario.indexOf(10) != -1 &&
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<AddIcon />}
                            to={"/clientes/" + urlParams.id + "/cuenta-corriente/planes/nuevo"}
                        >
                            Nuevo
                        </ButtonIcon>
                    }
                </div>
            </Grid>
        </Grid>
        <div className="tableContainer">
            <table width="100%" className="formato1" cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th align="left">Plan</th>
                        <th align="center">Inicio</th>
                        <th align="center">Fin</th>
                        <th align="right">Cuota</th>
                        <th align="right">Meses Deuda</th>
                        <th align="right">Deuda</th>
                        <th align="center">Próximo Período</th>
                        <th align="center">Acciones</th>
                    </tr>
                </thead>
                {
                    isSuccessGetRegistro &&
                    <tbody>
                        <tr>
                            <td align="left">{responseGetRegistro.plan.nombre}</td>
                            <td align="center">{<Moment date={responseGetRegistro.plan.inicio} format="DD-MM-YYYY" />}</td>
                            <td align="center">{(responseGetRegistro.plan.fin ? <Moment date={responseGetRegistro.plan.fin} format="DD-MM-YYYY" /> : '-')}</td>
                            <td align="right">
                                <NumericFormat
                                    value={responseGetRegistro.plan.cuota}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />
                            </td>
                            <td align="right">
                                {responseGetRegistro.deuda.periodos_deuda}
                            </td>
                            <td align="right">
                                <NumericFormat
                                    value={responseGetRegistro.deuda.deuda_importe}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />
                            </td>
                            <td align="center">{<Moment date={responseGetRegistro.deuda.abono_proximo_periodo} format="M - Y" />}</td>
                            <td align="center">
                                {
                                    permisosDelUsuario.indexOf(10) != -1 &&
                                    <ButtonIcon
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<DeleteOutlineIcon />}
                                        to={'/clientes/' + urlParams.id + '/cuenta-corriente/planes/eliminar'}
                                        marginRight
                                    />
                                }
                            </td>
                        </tr>
                    </tbody>
                }
                {
                    isErrorGetRegistro &&
                    <tbody>
                        <tr>
                            <td align="center" colSpan={8}>
                                <p>{errorDataGetRegistro.data.message}</p>
                            </td>
                        </tr>
                    </tbody>
                }
            </table>
        </div>

    </>)

}

