import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useRemovePlanclienteMutation } from '../../../../store/api/endpoints/clientesplanesApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import TituloMini from '../../../elementos/TituloMini'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

export default function ClientesPlanesEliminar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    const [
        removePlan,
        {
            data: responseRemovePlan,
            isUninitialized: isUninitializedRemovePlan,
            isLoading: isLoadingRemovePlan,
            isSuccess: isSuccessRemovePlan,
            isError: isErrorRemovePlan,
            error: errorDataRemovePlan,
        }
    ] = useRemovePlanclienteMutation()

    const handleSubmit = () => {
        removePlan(urlParams.id)
    }


    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorRemovePlan) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataRemovePlan.data.message
            }))
        }
    }, [isErrorRemovePlan])

    useEffect(() => {
        if (isSuccessRemovePlan) {
            dispatch(openFeedback({
                severity: 'success',
                message: "El plan se eliminó correctamente."
            }))

            navigate("/clientes/" + urlParams.id + "/cuenta-corriente")
        }
    }, [isSuccessRemovePlan])



    if (permisosDelUsuario.indexOf(10) == -1) return (<p>No tienes acceso a modificar clientes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingRemovePlan) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/cuenta-corriente"}>
                        Cuenta Corriente
                    </Link>
                    <Typography color="text.primary">Eliminar Plan</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Eliminar Plan">Eliminarás el plan vigente del cliente.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p>¿Seguro que deseas eliminar el plan del cliente?</p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="error" startIcon={<DeleteOutlineIcon />} >
                            Eliminar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

