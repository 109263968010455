import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { SketchPicker } from 'react-color'

import { useGetClienteByIdMutation, useGetClienteColoresMutation, useUpdateClienteColoresMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import HeaderVista from '../../../elementos/HeaderVista'
import TituloMini from '../../../elementos/TituloMini'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'

export default function ClienteColoresUpdate() {

    const urlParams = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [menu, setMenu] = useState('')
    const [menu_texto, setMenuTexto] = useState('')
    const [botones, setBotones] = useState('')
    const [botones_texto, setBotonesTexto] = useState('')
    const [footer, setFooter] = useState('')
    const [footer_texto, setFooterTexto] = useState('')
    const [fondo, setFondo] = useState('')
    const [fondo_texto, setFondoTexto] = useState('')
    const [lista, setLista] = useState('')
    const [lista_texto, setListaTexto] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteColoresMutation()

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
            error: errorDataGetCliente,
        }
    ] = useGetClienteByIdMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateClienteColoresMutation()

    const handleSubmit = () => {

        let body = {
            menu,
            menu_texto,
            botones,
            botones_texto,
            footer,
            footer_texto,
            fondo,
            fondo_texto,
            lista,
            lista_texto,
        }

        updateRegistro({ id: urlParams.id, body })
    }

    const handleChangeMenu = (color, event) => {
        setMenu(color.hex)
    }
    const handleChangeMenuTexto = (color, event) => {
        setMenuTexto(color.hex)
    }
    const handleChangeBotones = (color, event) => {
        setBotones(color.hex)
    }
    const handleChangeBotonesTexto = (color, event) => {
        setBotonesTexto(color.hex)
    }
    const handleChangeFooter = (color, event) => {
        setFooter(color.hex)
    }
    const handleChangeFooterTexto = (color, event) => {
        setFooterTexto(color.hex)
    }
    const handleChangeFondo = (color, event) => {
        setFondo(color.hex)
    }
    const handleChangeFondoTexto = (color, event) => {
        setFondoTexto(color.hex)
    }
    const handleChangeLista = (color, event) => {
        setLista(color.hex)
    }
    const handleChangeListaTexto = (color, event) => {
        setListaTexto(color.hex)
    }

    useEffect(() => {
        getRegistro(urlParams.id)
        getCliente(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorGetCliente) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetCliente.data.message
            }))
        }
    }, [isErrorGetCliente])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

            navigate("/clientes/" + urlParams.id)
        }
    }, [isSuccessUpdateRegistro])

    useEffect(() => {

        if (isSuccessGetRegistro) {

            setMenu(responseGetRegistro.menu)
            setMenuTexto(responseGetRegistro.menu_texto)
            setBotones(responseGetRegistro.botones)
            setBotonesTexto(responseGetRegistro.botones_texto)
            setFooter(responseGetRegistro.footer)
            setFooterTexto(responseGetRegistro.footer_texto)
            setFondo(responseGetRegistro.fondo)
            setFondoTexto(responseGetRegistro.fondo_texto)
            setLista(responseGetRegistro.lista)
            setListaTexto(responseGetRegistro.lista_texto)

        }

    }, [isSuccessGetRegistro])

    if (permisosDelUsuario.indexOf(9) == -1) return (<p>No tienes acceso a modificar los clientes.</p>)

    if (isUninitializedGetCliente || isLoadingGetCliente || isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetCliente.dominio}
                    </Link>
                    <Typography color="text.primary">Editar Colores</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Colores">Colores para la vista de clientes.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Menú</h4>
                        <div align="center">
                            <SketchPicker
                                color={menu}
                                onChangeComplete={handleChangeMenu}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Menú Texto</h4>
                        <div align="center">
                            <SketchPicker
                                color={menu_texto}
                                onChangeComplete={handleChangeMenuTexto}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Ejemplo</h4>
                        <div style={{
                            backgroundColor: menu,
                            color: menu_texto,
                            padding: '25px',
                            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                        }}>
                            <h3>Esto es un título</h3>
                            <p>Esto es un texto.</p>
                        </div>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Botones</h4>
                        <div align="center">
                            <SketchPicker
                                color={botones}
                                onChangeComplete={handleChangeBotones}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Botones Texto</h4>
                        <div align="center">
                            <SketchPicker
                                color={botones_texto}
                                onChangeComplete={handleChangeBotonesTexto}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Ejemplo</h4>
                        <div style={{
                            backgroundColor: botones,
                            color: botones_texto,
                            padding: '25px',
                            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                        }}>
                            <h3>Esto es un título</h3>
                            <p>Esto es un texto.</p>
                        </div>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Footer</h4>
                        <div align="center">
                            <SketchPicker
                                color={footer}
                                onChangeComplete={handleChangeFooter}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Footer Texto</h4>
                        <div align="center">
                            <SketchPicker
                                color={footer_texto}
                                onChangeComplete={handleChangeFooterTexto}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Ejemplo</h4>
                        <div style={{
                            backgroundColor: footer,
                            color: footer_texto,
                            padding: '25px',
                            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                        }}>
                            <h3>Esto es un título</h3>
                            <p>Esto es un texto.</p>
                        </div>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Fondo</h4>
                        <div align="center">
                            <SketchPicker
                                color={fondo}
                                onChangeComplete={handleChangeFondo}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Fondo Texto</h4>
                        <div align="center">
                            <SketchPicker
                                color={fondo_texto}
                                onChangeComplete={handleChangeFondoTexto}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Ejemplo</h4>
                        <div style={{
                            backgroundColor: fondo,
                            color: fondo_texto,
                            padding: '25px',
                            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                        }}>
                            <h3>Esto es un título</h3>
                            <p>Esto es un texto.</p>
                        </div>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Lista</h4>
                        <div align="center">
                            <SketchPicker
                                color={lista}
                                onChangeComplete={handleChangeLista}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Lista Texto</h4>
                        <div align="center">
                            <SketchPicker
                                color={lista_texto}
                                onChangeComplete={handleChangeListaTexto}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                        <h4 align="center">Ejemplo</h4>
                        <div style={{
                            backgroundColor: lista,
                            color: lista_texto,
                            padding: '25px',
                            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                        }}>
                            <h3>Esto es un título</h3>
                            <p>Esto es un texto.</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

