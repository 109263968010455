import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetUsuarioMutation, useGetAllUsuariosMutation } from '../../../store/api/endpoints/usuariosApiSlice'
import { useGetPermisosBackofficeAllMutation } from '../../../store/api/endpoints/permisosBackofficeApiSlice'


import { getUsuarioPermisos } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import ButtonIcon from '../../elementos/ButtonIcon'
import CheckboxList from '../../elementos/CheckboxList'

import EditIcon from '@mui/icons-material/Edit'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PercentIcon from '@mui/icons-material/Percent'
import PasswordIcon from '@mui/icons-material/Password'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import LockOpenIcon from '@mui/icons-material/LockOpen'

export default function Usuario() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [comision, setComision] = useState('0')
    const [permisosAll, setPermisosAll] = useState([])
    const [permisosElegidos, setPermisosElegidos] = useState([])
    const [vendedoresAll, setVendedoresAll] = useState([])
    const [vendedoresElegidos, setVendedoresElegidos] = useState([])

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetUsuarioMutation()

    const [
        getPermisosAll,
        {
            data: responseGetPermisosAll,
            isUninitialized: isUninitializedGetPermisosAll,
            isLoading: isLoadingGetPermisosAll,
            isSuccess: isSuccessGetPermisosAll,
            isError: isErrorGetPermisosAll,
            error: errorDataGetPermisosAll,
        }
    ] = useGetPermisosBackofficeAllMutation()

    const [
        getUsuariosAll,
        {
            data: responseGetUsuariosAll,
            isUninitialized: isUninitializedGetUsuariosAll,
            isLoading: isLoadingGetUsuariosAll,
            isSuccess: isSuccessGetUsuariosAll,
            isError: isErrorGetUsuariosAll,
            error: errorDataGetUsuariosAll,
        }
    ] = useGetAllUsuariosMutation()

    useEffect(() => {
        getPermisosAll()
        getUsuariosAll()
    }, [])

    useEffect(() => {
        if (isSuccessGetPermisosAll && isSuccessGetUsuariosAll) {
            setVendedoresAll(responseGetUsuariosAll)
            setPermisosAll(responseGetPermisosAll)
        }
    }, [isSuccessGetPermisosAll, isSuccessGetUsuariosAll])

    useEffect(() => {
        if (isErrorGetPermisosAll) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetPermisosAll.data.message
            }))
        }
    }, [isErrorGetPermisosAll])

    useEffect(() => {
        if (isErrorGetUsuariosAll) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetUsuariosAll.data.message
            }))
        }
    }, [isErrorGetUsuariosAll])

    useEffect(() => {
        if (isSuccessGetPermisosAll) getRegistro(urlParams.id)
    }, [permisosAll])

    useEffect(() => {

        if (isSuccessGetRegistro) {
            setNombre(responseGetRegistro.nombre)
            setEmail(responseGetRegistro.email)
            setTelWhatsapp(responseGetRegistro.tel_whatsapp)
            setComision(responseGetRegistro.comision)
            setVendedoresElegidos(responseGetRegistro.acceso_vendedores)
            setPermisosElegidos(responseGetRegistro.permisos)
        }

    }, [isSuccessGetRegistro])



    if (permisosDelUsuario.indexOf(2) == -1) return (<p>No tienes acceso a visualizar los usuarios.</p>)

    if (isUninitializedGetPermisosAll || isLoadingGetPermisosAll || isUninitializedGetUsuariosAll || isLoadingGetUsuariosAll || isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/usuarios"}>
                        Usuarios
                    </Link>
                    <Typography color="text.primary">{nombre}</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
            end={<>
                {(permisosDelUsuario.indexOf(7) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    to={"/usuarios/" + urlParams.id + "/editar"}
                    marginRight
                >
                    Editar
                </ButtonIcon> : '')}
                {(permisosDelUsuario.indexOf(7) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="error"
                    startIcon={<DeleteOutlineIcon />}
                    to={"/usuarios/" + urlParams.id + "/eliminar"}
                    marginRight
                >
                    Eliminar
                </ButtonIcon> : '')}
            </>}
        />
        <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Nombre del Usuario"
                        icon={<PersonIcon />}
                        value={nombre}
                        onChange={setNombre}
                        type="text"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Email"
                        icon={<AlternateEmailIcon />}
                        value={email}
                        onChange={setEmail}
                        type="email"
                        restricciones={['email']}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Whatsapp"
                        icon={<WhatsAppIcon />}
                        value={tel_whatsapp}
                        onChange={setTelWhatsapp}
                        type="tel"
                        restricciones={['soloNumeros']}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Comisión"
                        icon={<PercentIcon />}
                        value={comision}
                        onChange={setComision}
                        type="tel"
                        restricciones={['soloNumerosDecimales']}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Input
                        label="Contraseña"
                        icon={<PasswordIcon />}
                        value={"***"}
                        type="password"
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <br /><Divider />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <CheckboxList
                        titulo="Acceso Vendedores"
                        icon={<LockOpenIcon />}
                        elegidos={vendedoresElegidos}
                        opciones={vendedoresAll.map(elem => ({
                            codigo: elem._id,
                            nombre: elem.nombre,
                        }))}
                        handleChange={false}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CheckboxList
                        titulo="Permisos"
                        icon={<LockOpenIcon />}
                        elegidos={permisosElegidos}
                        opciones={permisosAll}
                        handleChange={false}
                        disabled
                    />
                </Grid>
            </Grid>
        </Container>

    </>)

}

