import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import ClientePagos from './ClientePagos'
import ClientePlanes from './ClientePlanes'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import ButtonIcon from '../../../elementos/ButtonIcon'
import ConfirmDelete from '../../../elementos/ConfirmDelete'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SettingsIcon from '@mui/icons-material/Settings'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt'

export default function ClienteCuentaCorriente() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])


    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar clientes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Typography color="text.primary">Cuenta Corriente</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
            end={
                <>
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<SettingsIcon />}
                        to={'/clientes/' + urlParams.id}
                        marginRight
                    />
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="success"
                        startIcon={<PointOfSaleIcon />}
                        to={'/clientes/' + urlParams.id + '/cuenta-corriente'}
                        marginRight
                    />
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<AppSettingsAltIcon />}
                        to={'/clientes/' + urlParams.id + '/app'}
                        marginRight
                    />
                    {
                        permisosDelUsuario.indexOf(11) != -1 &&
                        <ConfirmDelete
                            variant="contained"
                            size="small"
                            httpDelete={"cliente/" + urlParams.id}
                            returnUrl={"/clientes/"}
                            soloIcono
                        />
                    }
                </>
            }
        />
        <Container component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}><br /><br /></Grid>
                <Grid item xs={12} md={12}>
                    <ClientePlanes />
                </Grid>
                <Grid item xs={12} md={12}><br /><Divider /><br /></Grid>
                <Grid item xs={12} md={12}>
                    <ClientePagos />
                </Grid>
            </Grid>
        </Container>

    </>)

}

