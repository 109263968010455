import { tiendasApi } from '../api'

export const permisosAppApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPermisosAppPaginated: builder.mutation({
            query: ({ extraParams, body }) => ({
                url: 'permisos/' + extraParams.app + '/get-paginated',
                method: 'POST',
                body: body
            })
        }),
        getPermisoApp: builder.mutation({
            query: ({ app, id }) => ({
                url: 'permisos/' + app + '/' + id,
                method: 'GET'
            })
        }),
        createPermisoApp: builder.mutation({
            query: ({ app, body }) => ({
                url: 'permisos/' + app,
                method: 'POST',
                body: body
            })
        }),
        updatePermisoApp: builder.mutation({
            query: ({ app, body, id }) => ({
                url: 'permisos/' + app + '/' + id,
                method: 'PUT',
                body: body
            })
        }),
        removePermisoApp: builder.mutation({
            query: ({ app, id }) => ({
                url: 'permisos/' + app + '/' + id,
                method: 'DELETE'
            })
        }),
    })
})

export const {
    useGetPermisosAppPaginatedMutation,
    useGetPermisoAppMutation,
    useCreatePermisoAppMutation,
    useUpdatePermisoAppMutation,
    useRemovePermisoAppMutation,
} = permisosAppApiSlice