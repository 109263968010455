import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetClienteSucursalMutation, useUpdateClienteSucursalMutation } from '../../../../store/api/endpoints/clientessucusalesApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import { openFeedback } from '../../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import Input from '../../../elementos/Input'


import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

export default function ClienteSucursalesEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [barrio, setBarrio] = useState('')
    const [nombre, setNombre] = useState('')
    const [telefono, setTelefono] = useState('')
    const [whatsapp_texto, setWhatsappTexto] = useState('')
    const [whatsapp_link, setWhatsappLink] = useState('')
    const [direccion, setDireccion] = useState('')
    const [mapa, setMapa] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteByIdMutation()

    const [
        getSucursal,
        {
            data: responseGetSucursal,
            isUninitialized: isUninitializedGetSucursal,
            isLoading: isLoadingGetSucursal,
            isSuccess: isSuccessGetSucursal,
            isError: isErrorGetSucursal,
            error: errorDataGetSucursal,
        }
    ] = useGetClienteSucursalMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateClienteSucursalMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            codigo: urlParams.idSucursal,
            barrio,
            nombre,
            telefono,
            whatsapp_texto,
            whatsapp_link,
            direccion,
            mapa,
        }
        updateRegistro({ idCliente: urlParams.id, body })
    }


    useEffect(() => {
        getSucursal({ idCliente: urlParams.id, id: urlParams.idSucursal })
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    useEffect(() => {
        if (isErrorGetSucursal) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetSucursal.data.message
            }))
        }
    }, [isErrorGetSucursal])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    useEffect(() => {
        if (isSuccessGetSucursal) {
            setBarrio(responseGetSucursal.barrio)
            setNombre(responseGetSucursal.nombre)
            setTelefono(responseGetSucursal.telefono)
            setWhatsappTexto(responseGetSucursal.whatsapp_texto)
            setWhatsappLink(responseGetSucursal.whatsapp_link)
            setDireccion(responseGetSucursal.direccion)
            setMapa(responseGetSucursal.mapa)
        }
    }, [isSuccessGetSucursal])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡La sucursal se ha actualizado!"
            }))

            navigate("/clientes/" + urlParams.id + "/app")
        }
    }, [isSuccessUpdateRegistro])

    if (permisosDelUsuario.indexOf(10) == -1) return (<p>No tienes acceso a editar sucursales.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetRegistro.nombre}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/app"}>
                        App
                    </Link>
                    <Typography color="text.primary">Editar Sucursal</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
            end={<ConfirmDelete
                variant="contained"
                size="small"
                httpDelete={"clientes/app/gym/" + urlParams.id + "/sucursales/" + urlParams.idSucursal}
                returnUrl={"/clientes/" + urlParams.id + "/app"}
                soloIcono
            />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Barrio"
                            icon={<LocationOnIcon />}
                            helperText="Ej: CABA"
                            value={barrio}
                            onChange={setBarrio}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Nombre"
                            icon={<LocationOnIcon />}
                            helperText="Ej: Liniers"
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} ></Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Teléfono"
                            icon={<PhoneEnabledIcon />}
                            helperText="Ej: 11-5555-4444"
                            value={telefono}
                            onChange={setTelefono}
                            type="text"

                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Whatsapp Texto"
                            icon={<WhatsAppIcon />}
                            helperText="Ej: 11-5555-4444"
                            value={whatsapp_texto}
                            onChange={setWhatsappTexto}
                            type="text"

                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Whatsapp Link"
                            icon={<WhatsAppIcon />}
                            helperText="Ej: 5491155554444"
                            value={whatsapp_link}
                            onChange={setWhatsappLink}
                            type="text"

                        />
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Calle N°, Localidad"
                            icon={<LocationOnIcon />}
                            helperText="Ej: Calle 1234, CABA"
                            value={direccion}
                            onChange={setDireccion}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="URL Mapa"
                            icon={<LocationOnIcon />}
                            helperText="Ej: https://maps.google.com/1234"
                            value={mapa}
                            onChange={setMapa}
                            type="text"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

