import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetClienteByIdMutation, useGetClienteMarcaMutation, useUpdateClienteMarcaMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import TituloMini from '../../../elementos/TituloMini'
import Select from '../../../elementos/Select'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import StoreIcon from '@mui/icons-material/Store'
import PublicIcon from '@mui/icons-material/Public'
import CheckIcon from '@mui/icons-material/Check'

export default function ClienteMarcaUpdate() {

    const urlParams = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [marca, setMarca] = useState('')
    const [email, setEmail] = useState('')
    const [ej_cel, setEjCel] = useState('')
    const [telefono, setTelefono] = useState('')
    const [mapa, setMapa] = useState('')
    const [direccion, setDireccion] = useState('')
    const [instagram, setInstagram] = useState('')
    const [facebook, setFacebook] = useState('')
    const [tel_pais, setTelPais] = useState('')
    const [tel_area, setTelArea] = useState('')
    const [lenguaje, setLenguaje] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetClienteMarcaMutation()

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
            error: errorDataGetCliente,
        }
    ] = useGetClienteByIdMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateClienteMarcaMutation()

    const handleSubmit = () => {

        let body = {
            marca,
            email,
            ej_cel,
            telefono,
            mapa,
            direccion,
            instagram,
            facebook,
            tel_pais,
            tel_area,
            lenguaje,
        }

        updateRegistro({ id: urlParams.id, body })
    }

    useEffect(() => {
        getRegistro(urlParams.id)
        getCliente(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])
    
    useEffect(() => {
        if (isErrorGetCliente) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetCliente.data.message
            }))
        }
    }, [isErrorGetCliente])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    useEffect(() => {
        if (isSuccessUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

            navigate("/clientes/" + urlParams.id)
        }
    }, [isSuccessUpdateRegistro])

    useEffect(() => {

        if (isSuccessGetRegistro) {

            setMarca(responseGetRegistro.marca)
            setEmail(responseGetRegistro.email)
            setEjCel(responseGetRegistro.ej_cel)
            setTelefono(responseGetRegistro.telefono)
            setMapa(responseGetRegistro.mapa)
            setDireccion(responseGetRegistro.direccion)
            setInstagram(responseGetRegistro.instagram)
            setFacebook(responseGetRegistro.facebook)
            setTelPais(responseGetRegistro.tel_pais)
            setTelArea(responseGetRegistro.tel_area)
            setLenguaje(responseGetRegistro.lenguaje)

        }

    }, [isSuccessGetRegistro])

    if (permisosDelUsuario.indexOf(9) == -1) return (<p>No tienes acceso a modificar los clientes.</p>)

    if (isUninitializedGetCliente || isLoadingGetCliente || isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetCliente.dominio}
                    </Link>
                    <Typography color="text.primary">Editar Marca</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TituloMini titulo="Info de Marca">Información que visualizarán los clientes que deseen reservar un turno.</TituloMini>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Nombre de Marca"
                            icon={<StoreIcon />}
                            value={marca}
                            onChange={setMarca}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Email"
                            icon={<MailOutlineIcon />}
                            value={email}
                            onChange={setEmail}
                            restricciones={['email']}
                            type="email"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Dirección"
                            icon={<LocationOnIcon />}
                            value={direccion}
                            onChange={setDireccion}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Mapa"
                            icon={<LocationOnIcon />}
                            value={mapa}
                            onChange={setMapa}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Ej. Cel"
                            icon={<WhatsAppIcon />}
                            helperText="Formato 11-3333-4444. Sin cel #."
                            value={ej_cel}
                            onChange={setEjCel}
                            type="tel"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Whatsapp para clientes"
                            icon={<WhatsAppIcon />}
                            helperText="Formato 5491133334444. Sin cel #."
                            value={telefono}
                            onChange={setTelefono}
                            type="tel"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Código de País"
                            icon={<WhatsAppIcon />}
                            helperText="Formato 549"
                            value={tel_pais}
                            onChange={setTelPais}
                            type="tel"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Código de Área"
                            icon={<WhatsAppIcon />}
                            helperText="Formato 11"
                            value={tel_area}
                            onChange={setTelArea}
                            type="tel"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Instagram"
                            icon={<InstagramIcon />}
                            helperText="Formato doyturnos. Sin insta #."
                            value={instagram}
                            onChange={setInstagram}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Input
                            label="Facebook"
                            icon={<FacebookIcon />}
                            helperText="Formato doyturnos. Sin face #."
                            value={facebook}
                            onChange={setFacebook}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Select
                            label="Lenguaje"
                            icon={<PublicIcon />}
                            value={lenguaje}
                            options={[
                                {
                                    value: 'es_ar',
                                    label: 'Argentina'
                                },
                                {
                                    value: 'es_cl',
                                    label: 'Chile'
                                }
                            ]}
                            onChange={setLenguaje}
                            type="text"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    </>)

}

