import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './assets/css/Routes.css'

//Componentes Vistas
import Layout from './components/vistas/auth/Layout'
import Public from './components/vistas/auth/Public'
import RequireAuth from './components/vistas/auth/RequireAuth'
import Login from './components/vistas/auth/Login'

import Navbar from './components/vistas/Navbar'

import Home from './components/vistas/Home'

import Planes from './components/vistas/planes/Planes'
import Plan from './components/vistas/planes/Plan'
import PlanCrear from './components/vistas/planes/PlanCrear'
import PlanEditar from './components/vistas/planes/PlanEditar'

import Usuarios from './components/vistas/usuarios/Usuarios'
import UsuarioCrear from './components/vistas/usuarios/UsuarioCrear'
import Usuario from './components/vistas/usuarios/Usuario'
import UsuarioEditar from './components/vistas/usuarios/UsuarioEditar'
import UsuarioEliminar from './components/vistas/usuarios/UsuarioEliminar'

import PermisosApp from './components/vistas/permisos-app/PermisosApp'
import PermisosAppCrear from './components/vistas/permisos-app/PermisosAppCrear'
import PermisosAppEditar from './components/vistas/permisos-app/PermisosAppEditar'

import PermisosBackoffice from './components/vistas/permisos-backoffice/PermisosBackoffice'
import PermisoBackofficeCrear from './components/vistas/permisos-backoffice/PermisoBackofficeCrear'
import PermisoBackoffice from './components/vistas/permisos-backoffice/PermisoBackoffice'
import PermisoBackofficeEditar from './components/vistas/permisos-backoffice/PermisoBackofficeEditar'

import Clientes from './components/vistas/clientes/Clientes'
import ClienteCrear from './components/vistas/clientes/ClienteCrear'
import ClienteDetalle from './components/vistas/clientes/ClienteDetalle'
import ClienteCuentaCorriente from './components/vistas/clientes/cuenta-corriente/ClienteCuentaCorriente'
import ClientePlanesNuevo from './components/vistas/clientes/cuenta-corriente/ClientePlanesNuevo'
import ClientePlanesEliminar from './components/vistas/clientes/cuenta-corriente/ClientePlanesEliminar'
import ClientePagosNuevo from './components/vistas/clientes/cuenta-corriente/ClientePagosNuevo'
import ClientePagosEliminar from './components/vistas/clientes/cuenta-corriente/ClientePagosEliminar'
import ClienteApp from './components/vistas/clientes/app/ClienteApp'
import ClienteSucursalesNueva from './components/vistas/clientes/app/ClienteSucursalesNueva'
import ClienteSucursalesEditar from './components/vistas/clientes/app/ClienteSucursalesEditar'
import ClienteAgendasNueva from './components/vistas/clientes/app/ClienteAgendasNueva'
import ClienteAgendasEditar from './components/vistas/clientes/app/ClienteAgendasEditar'
import ClienteLoginsNuevo from './components/vistas/clientes/app/ClienteLoginsNuevo'
import ClienteLoginsEditar from './components/vistas/clientes/app/ClienteLoginsEditar'
import ClienteMarcaUpdate from './components/vistas/clientes/modulos/ClienteMarcaUpdate'
import ClienteContactoUpdate from './components/vistas/clientes/modulos/ClienteContactoUpdate'
import ClienteColoresUpdate from './components/vistas/clientes/modulos/ClienteColoresUpdate'
import ClienteFacturacionUpdate from './components/vistas/clientes/modulos/ClienteFacturacionUpdate'
import ClienteRutasIndex from './components/vistas/clientes/modulos/ClienteRutasIndex'
import ClienteRutasUpload from './components/vistas/clientes/modulos/ClienteRutasUpload'
import ClienteRutasDelete from './components/vistas/clientes/modulos/ClienteRutasDelete'



export default function Router() {



  return (
    <BrowserRouter>
      <Navbar />

      <Routes >

        <Route path="/" element={<Layout />} />
        <Route index element={<Public />} />
        <Route path="login" element={<Login />} />

        <Route element={<RequireAuth />}>

          <Route path="home" element={<Home />} />

          <Route path="usuarios" element={<Usuarios />} />
          <Route path="usuarios/crear" element={<UsuarioCrear />} />
          <Route path="usuarios/:id" element={<Usuario />} />
          <Route path="usuarios/:id/editar" element={<UsuarioEditar />} />
          <Route path="usuarios/:id/eliminar" element={<UsuarioEliminar />} />

          <Route path="planes" element={<Planes />} />
          <Route path="planes/:id" element={<Plan />} />
          <Route path="planes/crear" element={<PlanCrear />} />
          <Route path="planes/:id/editar" element={<PlanEditar />} />

          <Route path="permisos/app/:app" element={<PermisosApp />} />
          <Route path="permisos/app/:app/crear" element={<PermisosAppCrear />} />
          <Route path="permisos/app/:app/editar/:idPermiso" element={<PermisosAppEditar />} />

          <Route path="permisos/backoffice" element={<PermisosBackoffice />} />
          <Route path="permisos/backoffice/crear" element={<PermisoBackofficeCrear />} />
          <Route path="permisos/backoffice/:id" element={<PermisoBackoffice />} />
          <Route path="permisos/backoffice/:id/editar" element={<PermisoBackofficeEditar />} />
          
          <Route path="clientes" element={<Clientes />} />
          <Route path="clientes/crear" element={<ClienteCrear />} />
          <Route path="clientes/:id" element={<ClienteDetalle />} />
          <Route path="clientes/:id/cuenta-corriente" element={<ClienteCuentaCorriente />} />
          <Route path="clientes/:id/cuenta-corriente/planes/nuevo" element={<ClientePlanesNuevo />} />
          <Route path="clientes/:id/cuenta-corriente/planes/eliminar" element={<ClientePlanesEliminar />} />
          <Route path="clientes/:id/cuenta-corriente/pagos/nuevo" element={<ClientePagosNuevo />} />
          <Route path="clientes/:id/cuenta-corriente/pagos/eliminar/:idPago" element={<ClientePagosEliminar />} />
          <Route path="clientes/:id/app" element={<ClienteApp />} />
          <Route path="clientes/:id/app/sucursales/nueva" element={<ClienteSucursalesNueva />} />
          <Route path="clientes/:id/app/sucursales/:idSucursal" element={<ClienteSucursalesEditar />} />
          <Route path="clientes/:id/app/agendas/nueva" element={<ClienteAgendasNueva />} />
          <Route path="clientes/:id/app/agendas/:idAgenda" element={<ClienteAgendasEditar />} />
          <Route path="clientes/:id/app/logins/nuevo" element={<ClienteLoginsNuevo />} />
          <Route path="clientes/:id/app/logins/:idLogin" element={<ClienteLoginsEditar />} />
          <Route path="clientes/:id/edit/marca" element={<ClienteMarcaUpdate />} />
          <Route path="clientes/:id/edit/contacto" element={<ClienteContactoUpdate />} />
          <Route path="clientes/:id/edit/colores" element={<ClienteColoresUpdate />} />
          <Route path="clientes/:id/edit/facturacion" element={<ClienteFacturacionUpdate />} />
          <Route path="clientes/:id/edit/rutas" element={<ClienteRutasIndex />} />
          <Route path="clientes/:id/edit/rutas/:tipo" element={<ClienteRutasUpload />} />
          <Route path="clientes/:id/edit/rutas/:tipo/remove" element={<ClienteRutasDelete />} />

        </Route>

      </Routes>



    </BrowserRouter >
  );

}