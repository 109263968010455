import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useCreatePermisoBackofficeMutation } from '../../../store/api/endpoints/permisosBackofficeApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import Select from '../../elementos/Select'

import CheckIcon from '@mui/icons-material/Check'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import CachedIcon from '@mui/icons-material/Cached'
import NumbersIcon from '@mui/icons-material/Numbers'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

export default function PermisoBackofficeCrear() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        createRegistro,
        {
            data: responseCreateRegistro,
            isUninitialized: isUninitializedCreateRegistro,
            isLoading: isLoadingCreateRegistro,
            isSuccess: isSuccessCreateRegistro,
            isError: isErrorCreateRegistro,
            error: errorDataCreateRegistro,
        }
    ] = useCreatePermisoBackofficeMutation()

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            codigo,
            nombre,
        }
        createRegistro(body)
    }

    useEffect(() => {
        if (isSuccessCreateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/permisos/backoffice")

        }
    }, [isSuccessCreateRegistro])

    useEffect(() => {
        if (isErrorCreateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreateRegistro.data.message
            }))
        }
    }, [isErrorCreateRegistro])

    const [codigo, setCodigo] = useState('')
    const [nombre, setNombre] = useState('')
    
    if (permisosDelUsuario.indexOf(8) == -1) return (<p>No tienes acceso a crear los permisos.</p>)

    if (isLoadingCreateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/permisos/backoffice"}>
                        Permisos Backoffice
                    </Link>
                    <Typography color="text.primary">Crear</Typography>
                </Breadcrumbs>
            }
            icon={<LockOpenIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Codigo"
                            icon={<NumbersIcon />}
                            value={codigo}
                            onChange={setCodigo}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Nombre"
                            icon={<LockOpenIcon />}
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>

    </>)

}

