import { tiendasApi } from '../api'

export const clientesLoginsApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientesLoginsPaginated: builder.mutation({
            query: ({ extraParams, body }) => ({
                url: 'clientes/app/gym/' + extraParams.idCliente + '/logins/paginated',
                method: 'POST',
                body: body
            })
        }),
        getClienteLogin: builder.mutation({
            query: ({ idCliente, id }) => ({
                url: 'clientes/app/gym/' + idCliente + '/logins/' + id,
                method: 'GET'
            })
        }),
        createClienteLogin: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/logins',
                method: 'POST',
                body: body
            })
        }),
        updateClienteLogin: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/logins',
                method: 'PUT',
                body: body
            })
        }),
        removeClienteLogin: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/logins',
                method: 'DELETE',
                body: body
            })
        }),
    })
})

export const {
    useGetClientesLoginsPaginatedMutation,
    useGetClienteLoginMutation,
    useCreateClienteLoginMutation,
    useUpdateClienteLoginMutation,
    useRemoveClienteLoginMutation,
} = clientesLoginsApiSlice