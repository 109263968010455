import { useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useGetClienteByIdMutation, useRemoveRutaMutation } from '../../../../store/api/endpoints/clientesApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'
import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import HeaderVista from '../../../elementos/HeaderVista'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Grid, Button } from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

export default function ClienteRutasDelete() {

    const urlParams = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const handleDelete = () => {
        removeArchivo({idCliente: urlParams.id, ruta: urlParams.tipo})
    }

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
            error: errorDataGetCliente,
        }
    ] = useGetClienteByIdMutation()

    const [
        removeArchivo,
        {
            data: responseRemove,
            isUninitialized: isUninitializedRemove,
            isLoading: isLoadingRemove,
            isSuccess: isSuccessRemove,
            isError: isErrorRemove,
            error: errorDataRemove,
        }
    ] = useRemoveRutaMutation()

    useEffect(() => {
        getCliente(urlParams.id)
    }, [])

    useEffect(() => {
        if (isErrorGetCliente) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetCliente.data.message
            }))
        }
    }, [isErrorGetCliente])

    useEffect(() => {
        if (isSuccessRemove) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se eliminó el archivo!"
            }))
            navigate("/clientes/" + urlParams.id + "/edit/rutas")
        }
    }, [isSuccessRemove])

    useEffect(() => {
        if (isErrorRemove) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataRemove.data.message
            }))
        }
    }, [isErrorRemove])

    if (permisosDelUsuario.indexOf(9) == -1) return (<p>No tienes acceso a modificar los clientes.</p>)

    if (isUninitializedGetCliente || isLoadingGetCliente || isLoadingRemove) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/clientes"}>
                        Clientes
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                        {responseGetCliente.dominio}
                    </Link>
                    <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/edit/rutas"}>
                        Eliminar Archivo
                    </Link>
                    <Typography color="text.primary">{urlParams.tipo}</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <h3 style={{textAlign: 'center'}}>¿Estás seguro que deseas eliminar el archivo?</h3>
                    <h3 style={{textAlign: 'center'}}>{urlParams.tipo}</h3>
                    <p style={{textAlign: 'center'}}>
                        <Button type="button" onClick={handleDelete} variant="contained" color="error" startIcon={<DeleteOutlineIcon />} >
                            Eliminar
                        </Button>
                    </p>
                </Grid>
            </Grid>
        </Container>

    </>)

}

