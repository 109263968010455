import { tiendasApi } from '../api'

export const clientessucrsalesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientesSucursalesPaginated: builder.mutation({
            query: ({ extraParams, body }) => ({
                url: 'clientes/app/gym/' + extraParams.idCliente + '/sucursales/paginated',
                method: 'POST',
                body: body
            })
        }),
        getClienteSucursal: builder.mutation({
            query: ({ idCliente, id }) => ({
                url: 'clientes/app/gym/' + idCliente + '/sucursales/' + id,
                method: 'GET'
            })
        }),
        createClienteSucursal: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/sucursales',
                method: 'POST',
                body: body
            })
        }),
        updateClienteSucursal: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/sucursales',
                method: 'PUT',
                body: body
            })
        }),
        removeClienteSucursal: builder.mutation({
            query: ({ idCliente, body }) => ({
                url: 'clientes/app/gym/' + idCliente + '/sucursales',
                method: 'DELETE',
                body: body
            })
        }),
    })
})

export const {
    useGetClientesSucursalesPaginatedMutation,
    useGetClienteSucursalMutation,
    useCreateClienteSucursalMutation,
    useUpdateClienteSucursalMutation,
    useRemoveClienteSucursalMutation,
} = clientessucrsalesApiSlice