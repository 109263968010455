import { useEffect } from "react"
import { useLocation, Navigate, useNavigate, useParams } from "react-router-dom"

import { useDispatch } from 'react-redux'
import { setCredentials } from '../../../store/slices/auth'
import { useRefreshTokenMutation } from '../../../store/api/endpoints/selfApiSlice'

import Loading from "../../elementos/Loading"

const Public = () => {

    const navigate = useNavigate()
    const urlParams = useParams()
    const location = useLocation()

    const [refreshToken, { isLoading }] = useRefreshTokenMutation()
    const dispatch = useDispatch()

    const usarTokenDeLocalstorage = async () => {
        try {
            const userData = await refreshToken(localStorage.getItem("token")).unwrap()
            dispatch(setCredentials(userData))
            navigate(location.state.from.pathname)
        } catch (err) {
            localStorage.removeItem("token")
            navigate("/login")
        }
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            usarTokenDeLocalstorage()
        } else {
            localStorage.removeItem("token")
            navigate("/login")
        }
    }, [])

    return <Loading />

}

export default Public