import { tiendasApi } from '../api'

export const clientespagosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientePagosPaginated: builder.mutation({
            query: ({extraParams, body}) => ({
                url: 'clientes/cuenta-corriente/pagos/get-paginated/' + extraParams.idCliente,
                method: 'POST',
                body: body,
            })
        }),
        createClientePago: builder.mutation({
            query: (body) => ({
                url: 'clientes/cuenta-corriente/pagos',
                method: 'POST',
                body: body,
            })
        }),
        removeClientePago: builder.mutation({
            query: (id) => ({
                url: 'clientes/cuenta-corriente/pagos/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetClientePagosPaginatedMutation,
    useCreateClientePagoMutation,
    useRemoveClientePagoMutation,
} = clientespagosApiSlice