import React from 'react';
import { Link } from 'react-router-dom'

import '../../assets/css/Card.css'

import ButtonIcon from '../elementos/ButtonIcon'

import IconButton from '@mui/material/IconButton'

import EditIcon from '@mui/icons-material/Edit'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'


export default function Card(props) {

    return (
        <div className='card' style={{backgroundColor:'transparent'}}>
            <div className="titulo">{props.titulo}</div>
            <div className="img">
                {
                 props.img !== '' && <img src={props.img} width="90%" alt={props.titulo} />
                }
                {
                    props.img === '' && <ImageNotSupportedIcon />
                }
            </div>
            <div className="descripcion">{props.descripcion}</div>
            <div className='botones'>
                {
                    props.confirmDelete &&
                    <ButtonIcon to={props.confirmDelete.httpDelete} color="error" size="small" variant={"contained"} startIcon={<DeleteOutlineIcon />} />
                }
                {
                    props.rutaPencil &&
                    <Link to={props.rutaPencil}>
                        <IconButton color="neutral">
                            <EditIcon />
                        </IconButton>
                    </Link>
                }

            </div>
        </div>
    )

}