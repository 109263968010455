import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useGetClientePagosPaginatedMutation } from '../../../../store/api/endpoints/clientespagosApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'

import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import FactCheckIcon from '@mui/icons-material/TurnedIn'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'

export default function ClientesPagos() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const thead = []
    thead.push({ label: "ID", align: "right", ordenable: true })
    thead.push({ label: "Fecha", align: "center", ordenable: true })
    thead.push({ label: "Plan", align: "left", ordenable: true })
    thead.push({ label: "Usuario", align: "left", ordenable: true })
    thead.push({ label: "Modalidad", align: "center", ordenable: false })
    thead.push({ label: "Importe", align: "right", ordenable: true })
    thead.push({ label: "Merchant Order", align: "center", ordenable: false })
    thead.push({ label: "Período Abonado", align: "center", ordenable: true })
    thead.push({ label: "Acciones", align: "center", ordenable: false })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "id" })
    tbody.push({ tipo: "fechahora", valor: "fechahora" })
    tbody.push({ tipo: "texto", valor: "plan" })
    tbody.push({ tipo: "texto", valor: "usuario" })
    tbody.push({ tipo: "texto", valor: "modalidad" })
    tbody.push({ tipo: "precio", options: [",", ".", "$ "], valor: "importe" })
    tbody.push({ tipo: "texto", valor: "merchant_order" })
    tbody.push({ tipo: "fechacustom", options: "M - Y", valor: "periodo_abonado" })
    let botones = []
    botones.push({ color: "error", icono: <DeleteOutlineIcon />, ruta: "/clientes/" + urlParams.id + "/cuenta-corriente/pagos/eliminar/", valor: "id" })
    tbody.push({ tipo: "botones", valor: botones })


    if (permisosDelUsuario.indexOf(3) == -1) return (<p>No tienes acceso a visualizar los pagos.</p>)

    return (<>
        <Grid container>
            <Grid item xs={12} md={6} style={{ marginBottom: '0px', fontSize: '1.2rem' }}>
                <PointOfSaleIcon /> Pagos Realizados
            </Grid>
            <Grid item xs={12} md={6}>
                <div align="right">
                    {
                        permisosDelUsuario.indexOf(10) != -1 &&
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<AddIcon />}
                            to={"/clientes/" + urlParams.id + "/cuenta-corriente/pagos/nuevo"}
                        >
                            Nuevo
                        </ButtonIcon>
                    }
                </div>
            </Grid>
        </Grid>
        <TablaPaginada
            api={useGetClientePagosPaginatedMutation}
            thead={thead}
            tbody={tbody}
            orderDefault={[1, 1]}
            extraParams={{idCliente: urlParams.id}}
        />
    </>)

}

