import { tiendasApi } from '../api'

export const clientesplanesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPlanVigente: builder.mutation({
            query: (idCliente) => ({
                url: 'clientes/cuenta-corriente/planes/vigente/' + idCliente,
                method: 'GET',
            })
        }),
        createPlancliente: builder.mutation({
            query: (body) => ({
                url: 'clientes/cuenta-corriente/planes/crear',
                method: 'POST',
                body: body
            })
        }),
        removePlancliente: builder.mutation({
            query: (id) => ({
                url: 'clientes/cuenta-corriente/planes/vigente/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPlanVigenteMutation,
    useCreatePlanclienteMutation,
    useRemovePlanclienteMutation,
} = clientesplanesApiSlice