import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetAllUsuariosMutation, useGetUsuarioMutation, useRemoveUsuarioMutation } from '../../../store/api/endpoints/usuariosApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Select from '../../elementos/Select'
import ButtonIcon from '../../elementos/ButtonIcon'

import CheckIcon from '@mui/icons-material/Check'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PercentIcon from '@mui/icons-material/Percent'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

export default function Usuario() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getAllUsuarios,
        {
            data: responseGetAllUsuarios,
            isUninitialized: isUninitializedGetAllUsuarios,
            isLoading: isLoadingGetAllUsuarios,
            isSuccess: isSuccessGetAllUsuarios,
            isError: isErrorGetAllUsuarios,
            error: errorDataGetAllUsuarios,
        }
    ] = useGetAllUsuariosMutation()

    const [
        getUsuarioTentantivo,
        {
            data: responseGetUsuarioEliminar,
            isUninitialized: isUninitializedGetUsuarioEliminar,
            isLoading: isLoadingGetUsuarioEliminar,
            isSuccess: isSuccessGetUsuarioEliminar,
            isError: isErrorGetUsuarioEliminar,
            error: errorDataGetUsuarioEliminar,
        }
    ] = useGetUsuarioMutation()

    const [
        removeUsuario,
        {
            data: responseRemoveUsuario,
            isUninitialized: isUninitializedRemoveUsuario,
            isLoading: isLoadingRemoveUsuario,
            isSuccess: isSuccessRemoveUsuario,
            isError: isErrorRemoveUsuario,
            error: errorDataRemoveUsuario,
        }
    ] = useRemoveUsuarioMutation()

    const handleSubmit = () => {

        removeUsuario({ idBorrar: urlParams.id, idDerivar: usuarioDerivar })

    }

    useEffect(() => {

        getAllUsuarios()
        getUsuarioTentantivo(urlParams.id)

    }, [])

    useEffect(() => {

        if (isSuccessGetAllUsuarios) {

            let usuariosSinElQueEliminamos = []
            for (let i = 0; i < responseGetAllUsuarios.usuarios.length; i++) {
                if (responseGetAllUsuarios.usuarios[i]._id != urlParams.id) usuariosSinElQueEliminamos.push(responseGetAllUsuarios.usuarios[i])
            }

            setAllUsuarios(usuariosSinElQueEliminamos)

        }

    }, [isSuccessGetAllUsuarios])

    useEffect(() => {
        if (isErrorGetAllUsuarios) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetAllUsuarios.data.message
            }))
        }
    }, [isErrorGetAllUsuarios])

    useEffect(() => {

        if (isSuccessGetUsuarioEliminar) {

            setNombre(responseGetUsuarioEliminar.nombre)

        }

    }, [isSuccessGetUsuarioEliminar])

    useEffect(() => {
        if (isErrorGetUsuarioEliminar) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetUsuarioEliminar.data.message
            }))
        }
    }, [isErrorGetUsuarioEliminar])

    useEffect(() => {
        if (isSuccessRemoveUsuario) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha eliminado el registro!"
            }))

            navigate("/usuarios")

        }
    }, [isSuccessRemoveUsuario])

    useEffect(() => {
        if (isErrorRemoveUsuario) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataRemoveUsuario.data.message
            }))
        }
    }, [isErrorRemoveUsuario])

    const [allUsuarios, setAllUsuarios] = useState([])
    const [usuarioDerivar, setUsuarioDerivar] = useState('')
    const [nombre, setNombre] = useState('')

    if (permisosDelUsuario.indexOf(7) == -1) return (<p>No tienes acceso a eliminar los usuarios.</p>)

    if (isUninitializedGetAllUsuarios || isLoadingGetAllUsuarios || isUninitializedGetUsuarioEliminar || isLoadingGetUsuarioEliminar || isLoadingRemoveUsuario) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/usuarios"}>
                        Usuarios
                    </Link>
                    <Link underline="hover" color="inherit" to={"/usuarios/" + urlParams.id}>
                        {nombre}
                    </Link>
                    <Typography color="text.primary">Eliminar</Typography>
                </Breadcrumbs>
            }
            icon={<PeopleAltIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}></Grid>
                    <Grid item xs={12} md={6}>
                        <Select
                            label="Destino de Clientes"
                            helperText="¿A qué usuario se le asignaran los clientes del usuario eliminado?"
                            icon={<PersonIcon />}
                            value={usuarioDerivar}
                            options={allUsuarios.map((elem) => { return { label: elem.nombre, value: elem._id } })}
                            onChange={setUsuarioDerivar}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="error" startIcon={<DeleteOutlineIcon />} >
                            Eliminar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>

    </>)

}

