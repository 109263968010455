import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useUpdatePlanMutation, useGetPlanMutation } from '../../../store/api/endpoints/planesApiSlice'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { openFeedback } from '../../../store/slices/feedback'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Container, Divider, Grid, Button } from '@mui/material'

import HeaderVista from '../../elementos/HeaderVista'
import Loading from '../../elementos/Loading'
import Input from '../../elementos/Input'
import Select from '../../elementos/Select'

import CheckIcon from '@mui/icons-material/Check'
import FactCheckIcon from '@mui/icons-material/TurnedIn'
import CachedIcon from '@mui/icons-material/Cached'
import NumbersIcon from '@mui/icons-material/Numbers'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

export default function PlanesCrear() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetPlanMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdatePlanMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    useEffect(() => {
        if (isSuccessGetRegistro) {
            setNombre(responseGetRegistro.nombre)
            setCantagendas(responseGetRegistro.cant_agendas)
            setImporte(responseGetRegistro.importe)
            setPeriodo(responseGetRegistro.periodo)
        }
    }, [isSuccessGetRegistro])

    useEffect(() => {
        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }
    }, [isErrorGetRegistro])

    const handleSubmit = (e) => {

        e.preventDefault()
        
        let body = {
            nombre,
            importe,
            cant_agendas,
            periodo,
        }
        updateRegistro({ body, id: urlParams.id })
    }

    useEffect(() => {
        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha editado el registro!"
            }))

            navigate("/planes")

        }
    }, [isSuccessUpdateRegistro])

    useEffect(() => {
        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }
    }, [isErrorUpdateRegistro])

    const [nombre, setNombre] = useState('')
    const [importe, setImporte] = useState('')
    const [cant_agendas, setCantagendas] = useState('')
    const [periodo, setPeriodo] = useState('month')

    if (permisosDelUsuario.indexOf(6) == -1) return (<p>No tienes acceso a editar los planes.</p>)

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    return (<>
        <HeaderVista
            titulo={
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/planes"}>
                        Planes
                    </Link>
                    <Typography color="text.primary">Crear</Typography>
                </Breadcrumbs>
            }
            icon={<FactCheckIcon />}
        />
        <form onSubmit={handleSubmit}>
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Cant. Agendas"
                            icon={<NumbersIcon />}
                            value={cant_agendas}
                            onChange={setCantagendas}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Nombre del Plan"
                            icon={<FactCheckIcon />}
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Importe"
                            icon={<AttachMoneyIcon />}
                            value={importe}
                            onChange={setImporte}
                            type="tel"
                            restricciones={['soloNumeros']}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            label="Período"
                            icon={<CachedIcon />}
                            value={periodo}
                            options={[{ value: 'month', label: 'Month' }]}
                            onChange={setPeriodo}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='footerFormulario'>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>

    </>)

}

