import { tiendasApi } from '../api'

export const permisosBackofficeApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPermisosBackofficePaginated: builder.mutation({
            query: ({body}) => ({
                url: 'permisos/backoffice/get-paginated',
                method: 'POST',
                body: body
            })
        }),
        getPermisosBackofficeAll: builder.mutation({
            query: credentials => ({
                url: 'permisos/backoffice/all',
                method: 'GET'
            })
        }),
        getPermisoBackoffice: builder.mutation({
            query: id => ({
                url: 'permisos/backoffice/' + id,
                method: 'GET'
            })
        }),
        createPermisoBackoffice: builder.mutation({
            query: (body) => ({
                url: 'permisos/backoffice',
                method: 'POST',
                body: body
            })
        }),
        updatePermisoBackoffice: builder.mutation({
            query: ({ body, id }) => ({
                url: 'permisos/backoffice/' + id,
                method: 'PUT',
                body: body
            })
        }),
        removePermisoBackoffice: builder.mutation({
            query: id => ({
                url: 'permisos/backoffice/' + id,
                method: 'DELETE'
            })
        }),
    })
})

export const {
    useGetPermisosBackofficePaginatedMutation,
    useGetPermisoBackofficeMutation,
    useGetPermisosBackofficeAllMutation,
    useCreatePermisoBackofficeMutation,
    useUpdatePermisoBackofficeMutation,
    useRemovePermisoBackofficeMutation,
} = permisosBackofficeApiSlice